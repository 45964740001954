import React from "react";
import EnterToWinSection from "./FormSections/EnterToWinSection";

const EnterToWinForm = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <EnterToWinSection {...props} />;
};

export default EnterToWinForm;
