import React from "react";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Layout = ({ children }) => {
  const navigate = useNavigate();
  const pathname = window.location.href.includes("paradigm")
    ? "paradigm"
    : window.location.href.includes("anthem")
    ? "anthem"
    : "martinlogan";

  const handleClick = () => {};

  return (
    <div
      className={classNames(
        pathname === "martinlogan"
          ? "flex flex-col justify-between bg-gradient-to-b from-gray-700 via-gray-900 to-black"
          : "flex flex-col justify-between bg-gradient-to-b from-[#204870] to-[#192735]"
      )}
    >
      <div
        className={classNames(
          pathname === "martinlogan" ? "py-0" : "py-6",
          "flex justify-center  max-w-2xl items-center container mx-auto"
        )}
      >
        {/* <a href="/nhs_survey"> */}
        <img
          src={
            pathname === "martinlogan"
              ? "https://cdn.pmlsound.com/playground/audio_advice_live-survey-header.png"
              : "https://cdn.pmlsound.com/paradigm/paradigm_logo.png"
          }
          alt="logo"
          className={classNames(
            pathname === "martinlogan"
              ? "w-full pt-6"
              : "w-8/12, cursor-pointer"
          )}
          onClick={handleClick}
        />
        {/* </a> */}
      </div>
      <div className="w-full">
        <div className="py-3">
          <div>{children}</div>
        </div>
      </div>
      <div className="flex flex-col justify-center max-w-2xl items-center container mx-auto">
        <img
          src={
            pathname === "martinlogan"
              ? "https://cdn.pmlsound.com/martinlogan/Motion_Series/martinlogan_primary_logo_white.png"
              : "https://cdn.pmlsound.com/paradigm/paradigm_logo.png"
          }
          alt="logo"
          className={classNames(
            pathname === "martinlogan" ? "w-28" : "md:w-48 md:h-9 w-28 h-6"
          )}
        />
        <p className="text-gray-50 pb-10">
          {pathname === "martinlogan"
            ? `© ${new Date().getFullYear()} MartinLogan Ltd. All rights reserved.`
            : `© ${new Date().getFullYear()} Paradigm Electronics. All rights reserved.`}
        </p>
      </div>
    </div>
  );
};

export default Layout;
