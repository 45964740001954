import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function LearnMoreSuccess() {
  const navigate = useNavigate();

  const month = moment(new Date()).format("M");
  const day = moment(new Date()).format("DD");
  // console.log(month, day);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <section className="px-6 lg:px-8" id="hero">
        <div className="max-w-2xl mx-auto text-gray-50">
          <p className="py-6 text-2xl text-center font-logo leading-tight">
            Thank you for taking the time to complete our survey! Please note
            that the prize fulfillment will occur in late October 2024. Your
            feedback is greatly appreciated, and we wish you the best of luck in
            the contest!
          </p>
        </div>
      </section>
      <div className="max-w-2xl mx-auto px-8">
        <a
          className="mt-16 btn-next text text-gray-50 py-1 bg-green-500 border-green-500"
          href="https://www.martinlogan.com/en/"
        >
          Explore MartinLogan
        </a>
      </div>
    </React.Fragment>
  );
}

export default LearnMoreSuccess;
