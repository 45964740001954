import React, { useState } from "react";
import ReactSlider from "react-slider";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const RangeSliderRC = ({ name, setFieldValue, formValue }) => {
  const pathname = window.location.href.includes("paradigm")
    ? "paradigm"
    : window.location.href.includes("anthem")
    ? "anthem"
    : "martinlogan";
  return (
    <div>
      <ReactSlider
        className=""
        marks
        min={1}
        max={10}
        defaultValue={1}
        onChange={(value) => setFieldValue(name, value)}
        renderThumb={(props, state) => (
          <div
            {...props}
            style={{ ...props.style, zIndex: 20 }}
            className="relative flex flex-col items-center w-8 h-8 -mt-2 outline-none"
          >
            <div
              className={classNames(
                pathname !== "martinlogan" ? "bg-gray-900 " : "bg-[#d59028]",
                "absolute top-0 inline-block px-2 py-1 mb-2 -mt-8 text-xs text-white rounded-sm whitespace-nowrap"
              )}
            >
              {formValue} {formValue > 1}
            </div>
            <div className="w-8 h-8 bg-white border-4 border-gray-500 rounded-full shadow-lg cursor-pointer" />
          </div>
        )}
        renderTrack={(props, state) => (
          <div
            {...props}
            className={classNames(
              pathname !== "martinlogan" ? "bg-yellow-400" : "bg-[#d59028]",
              "h-4 rounded-full cursor-pointer"
            )}
          />
        )}
      />
    </div>
  );
};

export default RangeSliderRC;
