import React from "react";
import SocialButton from "./gooey_social_button/SocialButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faFlickr,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
const navigation = {
  main: [
    { name: "Explore Paradigm", href: "https://www.paradigm.com/" },
    {
      name: "Anthem Room Correction (ARC)",
      href: "https://www.anthemarc.com/index.php?utm_source=AnthemAV.com&utm_medium=Online&utm_campaign=Anthem%20Room%20Correction&utm_content=Redirect",
    },
    {
      name: "Find A Dealer",
      href: "https://www.paradigm.com/en/dealer-locator",
    },
  ],
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/anthemav",
      icon: (props) => (
        <svg
          width="35"
          height="35"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M35 17.5C35 7.84 27.16 0 17.5 0C7.84 0 0 7.84 0 17.5C0 25.97 6.02 33.0225 14 34.65V22.75H10.5V17.5H14V13.125C14 9.7475 16.7475 7 20.125 7H24.5V12.25H21C20.0375 12.25 19.25 13.0375 19.25 14V17.5H24.5V22.75H19.25V34.9125C28.0875 34.0375 35 26.5825 35 17.5Z"
            fill="#9CA3AF"
          />
        </svg>
      ),
      icon2: faFacebook,
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/anthem_av/",
      icon: (props) => (
        <svg
          width="35"
          height="35"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.5 20.5625C15.8594 20.5625 14.4375 19.25 14.4375 17.5C14.4375 15.8594 15.75 14.4375 17.5 14.4375C19.1406 14.4375 20.5625 15.75 20.5625 17.5C20.5625 19.1406 19.1406 20.5625 17.5 20.5625Z"
            fill="#9CA3AF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.2188 10.0625H13.7812C12.9062 10.1719 12.4688 10.2812 12.1406 10.3906C11.7031 10.5 11.375 10.7187 11.0469 11.0469C10.7872 11.3065 10.6646 11.5662 10.5163 11.88C10.4772 11.9627 10.4362 12.0494 10.3906 12.1406C10.3737 12.1914 10.3542 12.2448 10.3332 12.302C10.2188 12.6146 10.0625 13.0416 10.0625 13.7812V21.2187C10.1719 22.0937 10.2812 22.5312 10.3906 22.8594C10.5 23.2969 10.7188 23.625 11.0469 23.9531C11.3065 24.2128 11.5662 24.3354 11.88 24.4837C11.9628 24.5228 12.0493 24.5637 12.1406 24.6094C12.1914 24.6263 12.2448 24.6458 12.302 24.6668C12.6146 24.7812 13.0416 24.9375 13.7812 24.9375H21.2188C22.0937 24.8281 22.5312 24.7187 22.8594 24.6094C23.2969 24.5 23.625 24.2812 23.9531 23.9531C24.2128 23.6935 24.3354 23.4338 24.4837 23.12C24.5228 23.0373 24.5637 22.9507 24.6094 22.8594C24.6263 22.8086 24.6458 22.7552 24.6668 22.698C24.7812 22.3854 24.9375 21.9584 24.9375 21.2187V13.7812C24.8281 12.9062 24.7188 12.4687 24.6094 12.1406C24.5 11.7031 24.2812 11.375 23.9531 11.0469C23.6935 10.7872 23.4338 10.6646 23.12 10.5163C23.0374 10.4772 22.9505 10.4362 22.8594 10.3906C22.8086 10.3737 22.7552 10.3542 22.698 10.3332C22.3854 10.2188 21.9584 10.0625 21.2188 10.0625ZM17.5 12.7969C14.875 12.7969 12.7969 14.875 12.7969 17.5C12.7969 20.125 14.875 22.2031 17.5 22.2031C20.125 22.2031 22.2031 20.125 22.2031 17.5C22.2031 14.875 20.125 12.7969 17.5 12.7969ZM23.4062 12.6875C23.4062 13.2916 22.9166 13.7812 22.3125 13.7812C21.7084 13.7812 21.2188 13.2916 21.2188 12.6875C21.2188 12.0834 21.7084 11.5937 22.3125 11.5937C22.9166 11.5937 23.4062 12.0834 23.4062 12.6875Z"
            fill="#9CA3AF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 17.5C0 7.83502 7.83502 0 17.5 0C27.165 0 35 7.83502 35 17.5C35 27.165 27.165 35 17.5 35C7.83502 35 0 27.165 0 17.5ZM13.7812 8.42187H21.2188C22.2031 8.53125 22.8594 8.64062 23.4062 8.85937C24.0625 9.1875 24.5 9.40625 25.0469 9.95312C25.5938 10.5 25.9219 11.0469 26.1406 11.5937C26.3594 12.1406 26.5781 12.7969 26.5781 13.7812V21.2187C26.4687 22.2031 26.3594 22.8594 26.1406 23.4062C25.8125 24.0625 25.5938 24.5 25.0469 25.0469C24.5 25.5937 23.9531 25.9219 23.4062 26.1406C22.8594 26.3594 22.2031 26.5781 21.2188 26.5781H13.7812C12.7969 26.4687 12.1406 26.3594 11.5938 26.1406C10.9375 25.8125 10.5 25.5937 9.95312 25.0469C9.40625 24.5 9.07812 23.9531 8.85938 23.4062C8.64063 22.8594 8.42188 22.2031 8.42188 21.2187V13.7812C8.53125 12.7969 8.64063 12.1406 8.85938 11.5937C9.1875 10.9375 9.40625 10.5 9.95312 9.95312C10.5 9.40625 11.0469 9.07812 11.5938 8.85937C12.1406 8.64062 12.7969 8.42187 13.7812 8.42187Z"
            fill="#9CA3AF"
          />
        </svg>
      ),
      icon2: faInstagram,
    },
    {
      name: "Twitter",
      href: "https://twitter.com/anthemav",
      icon: (props) => (
        <svg
          width="35"
          height="35"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 17.5C0 7.83502 7.83502 0 17.5 0C27.165 0 35 7.83502 35 17.5C35 27.165 27.165 35 17.5 35C7.83502 35 0 27.165 0 17.5ZM24.1719 12.5781C24.9375 12.4688 25.5938 12.3594 26.25 12.0312C25.8125 12.7969 25.1562 13.4531 24.3906 13.8906C24.6094 19.0312 20.8906 24.6094 14.2188 24.6094C12.25 24.6094 10.3906 23.9531 8.75 22.9688C10.6094 23.1875 12.5781 22.6406 13.8906 21.6562C12.25 21.6562 10.9375 20.5625 10.5 19.1406C11.0469 19.25 11.5938 19.1406 12.1406 19.0312C10.5 18.5938 9.29688 17.0625 9.29688 15.4219C9.84375 15.6406 10.3906 15.8594 10.9375 15.8594C9.40625 14.7656 8.85938 12.6875 9.84375 11.0469C11.7031 13.2344 14.3281 14.6562 17.2812 14.7656C16.7344 12.5781 18.4844 10.3906 20.7812 10.3906C21.7656 10.3906 22.75 10.8281 23.4062 11.4844C24.2812 11.2656 25.0469 11.0469 25.7031 10.6094C25.4844 11.4844 24.9375 12.1406 24.1719 12.5781Z"
            fill="#9CA3AF"
          />
        </svg>
      ),
      icon2: faTwitter,
    },
    {
      name: "Flickr",
      href: "https://www.flickr.com/photos/anthemav/albums/72177720296372753",
      icon: (props) => (
        <svg
          width="35"
          height="35"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 17.5C0 27.165 7.83501 35 17.5 35C27.165 35 35 27.165 35 17.5C35 7.83501 27.165 0 17.5 0C7.83501 0 0 7.83501 0 17.5ZM26.4687 17.5C26.4687 19.7969 24.6094 21.6563 22.3125 21.6563C20.0156 21.6563 18.1562 19.7969 18.1562 17.5C18.1562 15.2031 20.0156 13.3437 22.3125 13.3437C24.6094 13.3437 26.4687 15.2031 26.4687 17.5ZM12.7969 21.6563C15.0937 21.6563 16.9531 19.7969 16.9531 17.5C16.9531 15.2031 14.9844 13.3437 12.7969 13.3437C10.5 13.3437 8.64062 15.2031 8.64062 17.5C8.64062 19.7969 10.5 21.6563 12.7969 21.6563Z"
            fill="#9CA3AF"
          />
        </svg>
      ),
      icon2: faFlickr,
    },
    {
      name: "YouTube",
      href: "https://www.youtube.com/user/AnthemAVTV",
      icon: (props) => (
        <svg
          width="35"
          height="35"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.3438 17.5L15.75 14.875V20.125L20.3438 17.5Z"
            fill="#9CA3AF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 17.5C0 7.83502 7.83502 0 17.5 0C27.165 0 35 7.83502 35 17.5C35 27.165 27.165 35 17.5 35C7.83502 35 0 27.165 0 17.5ZM24.2813 11.7031C25.0469 11.9219 25.5938 12.4688 25.8125 13.2344C26.25 14.6563 26.25 17.5 26.25 17.5C26.25 17.5 26.25 20.3438 25.9219 21.7656C25.7031 22.5313 25.1562 23.0781 24.3906 23.2969C22.9687 23.625 17.5 23.625 17.5 23.625C17.5 23.625 11.9219 23.625 10.6094 23.2969C9.84375 23.0781 9.29688 22.5313 9.07813 21.7656C8.75 20.3438 8.75 17.5 8.75 17.5C8.75 17.5 8.75 14.6563 8.96875 13.2344C9.1875 12.4688 9.73438 11.9219 10.5 11.7031C11.9219 11.375 17.3906 11.375 17.3906 11.375C17.3906 11.375 22.9688 11.375 24.2813 11.7031Z"
            fill="#9CA3AF"
          />
        </svg>
      ),
      icon2: faYoutube,
    },
  ],
};
const Footer = () => {
  return (
    <footer className="bg-black z-30 relative">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          {navigation.main.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <a
                href={item.href}
                className="text-base text-gray-200 hover:text-gray-50"
                target="_blank"
              >
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <div className="mt-10 flex justify-center space-x-6 lg:hidden">
          {navigation.social.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-gray-400 hover:text-gray-600"
              target="_blank"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="mt-10 lg:flex justify-center space-x-6 hidden">
          <SocialButton data={navigation} />
        </div>

        <p className="mt-10 text-center text-base text-gray-200">
          © {new Date().getFullYear()} Paradigm Electronics Inc. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
