import React from "react";
import { at } from "lodash";
import { useField } from "formik";

import { Formik, Field, Form, ErrorMessage } from "formik";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { CheckCircleIcon } from "@heroicons/react/solid";
export default function InputField(props) {
  const pathname = window.location.href.includes("paradigm")
    ? "paradigm"
    : window.location.href.includes("anthem")
    ? "anthem"
    : "martinlogan";
  const { errorText, setFieldValue, setField, ...rest } = props;
  const [field, meta] = useField(props);

  function _renderHelperText() {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return error;
    }
  }

  return (
    // <TextField
    //   type="text"
    //   error={meta.touched && meta.error && true}
    //   helperText={_renderHelperText()}
    //   {...field}
    //   {...rest}
    // />
    // <Field name={field.name}>
    //   {({ field, meta }) => (
    //     <div>
    //       {/* {meta.touched && meta.error && (
    //         <div className="absolute inset-y-0 right-0 pr-2 pt-5 flex items-center pointer-events-none">
    //           <ExclamationCircleIcon
    //             className="h-5 w-5 text-red-400"
    //             aria-hidden="true"
    //           />
    //         </div>
    //       )} */}

    //       {meta.value && !meta.error && (
    //         <div className="absolute inset-y-0 right-0 pr-2 pt-5  flex items-center pointer-events-none">
    //           <CheckCircleIcon
    //             className="h-5 w-5 text-green-400"
    //             aria-hidden="true"
    //           />
    //         </div>
    //       )}

    //       <input
    //         // disabled={rest.disabled}
    //         type="text"
    //         {...field}
    //         className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
    //         // value={rest.value}
    //       />
    //       {meta.touched && meta.error && (
    //         <div className="absolute text-red-700 font- text-sm">
    //           {meta.error}!
    //         </div>
    //       )}
    //     </div>
    //   )}
    // </Field>
    <>
      <label className="block text-sm font-medium text-gray-700">
        {/* {rest.label} */}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <Field
          type="text"
          {...field}
          {...rest}
          placeholder={props.placeholder}
          className={`mt-1 input-text-noerror ${
            (meta.error && meta.touched) || pathname !== "martinlogan"
              ? "focus:border-red-500 focus:ring-red-500 border-red-500 bg-gray-400 bg-opacity-25"
              : (meta.error && meta.touched) || pathname === "martinlogan"
              ? "focus:border-[#d59028] focus:ring-[#d59028] border-[#d59028] bg-gray-400 bg-opacity-25"
              : "input-text"
          }`}
          // helperText={_renderHelperText}
          error={meta.touched && meta.error && true}
        />
        {/* {meta.error && meta.touched ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : null}
        {meta.value && !meta.error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
        )} */}
      </div>

      <ErrorMessage
        component="div"
        {...rest}
        className="text-red-600 md:text-2xl text-lg mt-2"
      />
    </>
  );
}
