import React, { Fragment, useState } from "react";
import { Popover, Transition, Dialog } from "@headlessui/react";
import { XIcon, MenuAlt1Icon } from "@heroicons/react/outline";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const navigation = [
  { name: "Explore Anthem", href: "https://anthemav.com" },
  // { name: "ARC", href: "https://anthemarc.com" },
  { name: "Find a Dealer", href: "https://www.anthemav.com/locator/index.php" },
];
const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <>
      <header
        className="top-0 w-full  fixed z-50 shadow-md shadow-red-700/40 "
        style={{ zIndex: 100 }}
      >
        <div className="relative bg-black z-50 ">
          <div className="max-w-7xl mx-auto  px-4 md:px-4 xl:px-0">
            <nav
              className="mx-auto flex max-w-7xl items-center justify-between py-2 lg:px-8"
              aria-label="Global"
            >
              <div className="flex flex-1">
                <div className="hidden lg:flex">
                  {navigation.map((item, itemIdx) => (
                    <>
                      <a
                        key={itemIdx}
                        href={item.href}
                        target={"_blank"}
                        className={classNames(
                          itemIdx === 0 ? "mr-4" : "mr-0",
                          "text-sm font-medium  text-gray-300 hover:text-white  hover:underline underline-offset-[18px] hover:shadow-lg shadow-white"
                        )}
                      >
                        {item.name}
                      </a>

                      {itemIdx === 1 && (
                        <span className="">
                          <span class="relative flex h-3 w-3">
                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                            <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                          </span>
                        </span>
                      )}
                    </>
                  ))}
                </div>
                <div className="flex lg:hidden">
                  <button
                    type="button"
                    className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-50"
                    onClick={() => setMobileMenuOpen(true)}
                  >
                    <span className="sr-only">Open main menu</span>
                    <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  class="h-8 w-auto sm:h-10"
                  src="https://cdn.pmlsound.com/anthem/avm90/images/anthem-logo-horizontal-wht.svg"
                  alt=""
                />
              </a>
              <div className="flex flex-1 justify-end">
                <a
                  href="#"
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  Log in <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </nav>
            <Dialog
              as="div"
              className="lg:hidden"
              open={mobileMenuOpen}
              onClose={setMobileMenuOpen}
            >
              <div className="fixed inset-0 z-10" />
              <Dialog.Panel className="fixed inset-y-0 left-0 z-10 w-full overflow-y-auto bg-white px-6 py-6">
                <div className="flex items-center justify-between">
                  <div className="flex flex-1">
                    <button
                      type="button"
                      className="-m-2.5 rounded-md p-2.5 text-gray-700"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <a href="#" className="-m-1.5 p-1.5">
                    <span className="sr-only">Your Company</span>
                    <img className="h-8 w-auto" src="" alt="" />
                  </a>
                  <div className="flex flex-1 justify-end">
                    <a
                      href="#"
                      className="text-sm font-semibold leading-6 text-gray-900"
                    >
                      Log in <span aria-hidden="true">&rarr;</span>
                    </a>
                  </div>
                </div>
                <div className="mt-6 space-y-2">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </Dialog.Panel>
            </Dialog>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
