import checkoutFormModel from "./checkoutFormModel";
const {
  formField: {
    title_of_principle,
    first_name_of_principle,
    last_name_of_principle,
    date_business_commenced,
    company_name,
    company_phone_number,
    company_email,
    company_website_url,
    total_annual_sales,
    number_of_employees,
    ownership_type,
    company_unit,
    company_street_address,
    company_city,
    company_state,
    company_postal_code,
    company_country,
    percentage_annual_sales_audio,
    other_authorized_speaker_brands,
    other_authorized_electronics_brands,
    control_automation_brands,
  },
} = checkoutFormModel;

export default {
  [title_of_principle.name]: "",
  [first_name_of_principle.name]: "",
  [last_name_of_principle.name]: "",
  [date_business_commenced.name]: "",
  [company_name.name]: "",
  [company_phone_number.name]: "",
  [company_email.name]: "",
  [company_unit.name]: "",
  [company_street_address.name]: "",
  [company_city.name]: "",
  [company_state.name]: "",
  [company_postal_code.name]: "",
  [company_country.name]: "",
  [company_website_url.name]: "",
  [total_annual_sales.name]: "",
  [number_of_employees.name]: "",
  [ownership_type.name]: "",
  [percentage_annual_sales_audio.name]: "",
  [other_authorized_speaker_brands.name]: [],
  [other_authorized_electronics_brands.name]: [],
  [control_automation_brands.name]: [],
};
