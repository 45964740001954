import React from "react";

const NhsSocial = () => {
  return (
    <div>
      <section className="" id="hero">
        <div className="relative lg:bg-[url('https://cdn.pmlsound.com/paradigm/NHS/pe-bbc-nat-home-show-hero-desktop.jpg')] bg-[url('https://cdn.pmlsound.com/paradigm/NHS/pe-bbc-nat-home-show-hero-mobile.jpg')] bg-cover bg-no-repeat h-screen">
          <div className="absolute inset-x-0 lg:bottom-52 bottom-0 grid lg:grid-cols-2 grid-cols-1 content-end">
            <div className="lg:order-first order-last"></div>

            <div className="lg:py-10 py-6 text-white sm:mt-0 lg:pr-0 pr-0 max-w-2xl lg:order-first order-last">
              <p className="font-heading mt-2 lg:text-[48px] text-3xl font-bold text-[#A6F400] lg:px-0 px-6 text-center">
                HEAR THE PLANET
              </p>
              <p className="font-heading mt-2 lg:text-[48px] text-3xl font-bold text-[#A6F400] lg:px-0 px-6 text-center">
                AT ITS BEST
              </p>
              <p className="font-heading mt-2 lg:text-[25.5px] text-xl font-light text-gray-50 lg:px-0 px-6 text-center uppercase text-stroke-3">
                In Partnership with
              </p>
              <div className="flex justify-center">
                <img
                  className="h-14 w-auto"
                  src="https://cdn.pmlsound.com/paradigm/decor_v2/paradigm_logo.svg"
                  alt=""
                />
              </div>
              <p className="lg:text-stroke-3 font-nhs lg:text-[22.5px] text-xl font-light text-gray-50 lg:px-0 px-6 text-center capitalize">
                Performance Audio. Canadian Made.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-10 lg:px-24 px-6 max-w-7xl mx-auto">
        <p className="font-heading mt-2 lg:text-5xl text-3xl font-bold text-gray-900">
          Step into the BBC Earth Viewing Lounge:
          <br /> A Paradigm-Powered Experience at the National Home Show
        </p>
        <p className="text-lg py-6">
          Immerse yourself in an audiovisual experience like no other.
        </p>
        <p className="text-lg">
          <b>Who We Are:</b>
          <br />
          Paradigm is an industry-leading Canadian company that specializes in
          the design and manufacturing of high-performance loudspeakers and
          audio equipment. Since 1982, we’ve been at the forefront of the
          industry pioneering innovative audio solutions that redefine
          excellence. As we enter our fifth decade, our commitment to pushing
          the boundaries of audio science remains unwavering.
        </p>
        <p className="text-lg py-6">
          <b>What You’ll Experience:</b>
          <br />
          Get the first Canadian peek at footage from Planet Earth III,
          presented with the unmatched clarity and depth of Paradigm’s
          exceptional sound.
          <br />
          <br />
          But the experience doesn’t end there...
        </p>
      </section>
      <section id="contest">
        <div className="relative lg:h-screen">
          <div className="absolute inset-x-0">
            <img
              src="https://cdn.pmlsound.com/paradigm/NHS/prize-group-image-desktop-2.jpg"
              alt=""
              className="h-full w-full object-cover object-center lg:block hidden"
            />
          </div>
          <div aria-hidden="true" className="relative h-96 w-full lg:hidden" />
          <div aria-hidden="true" className="relative h-32 w-full lg:hidden" />
          <div className="px-6 lg:px-8 absolute inset-x-0 inset-y-0 pb-6 items-end sm:flex sm:items-center sm:justify-start lg:inset-x-auto lg:w-[44rem] lg:flex-col md:flex-col lg:items-end xl:pt-10">
            <div className="mt-6 flex-col flex-shrink-0 items-center justify-center rounded-md py-3 text-gray-900 sm:mt-0 lg:pl-16 lg:w-full">
              <p className="font-heading mt-2 lg:text-5xl text-3xl font-bold text-gray-900">
                After experiencing the demo, share your feedback with us through
                a brief survey and get a chance to win your own home theatre
                system valued at $10,000.
              </p>
              <p className="text-lg py-6">
                This incredible prize package includes:
              </p>
              <p className="lg:text-base text-sm text-gray-900 mb-6">
                <ul className="text-lg list-disc list-inside">
                  <li>A pair of Premier 800F floorstanding speakers</li>
                  <li>The Premier 600C center channel speaker</li>
                  <li>A pair of Surround 3 speakers</li>
                  <li>A Defiance X10 subwoofer</li>
                  <li>An Anthem MRX 540 audio/video receiver</li>
                </ul>
              </p>
              <p className="text-lg">
                We're also providing a team of expert integrators to ensure a
                seamless installation. <br />
                <br />
                <b>
                  But here’s the deal: You must attend the National Home Show to
                  win. Don’t miss out on this opportunity!
                </b>
              </p>
            </div>
          </div>

          <img
            src="https://cdn.pmlsound.com/paradigm/NHS/prize-group-image-mobile-2.jpg"
            alt=""
            className="h-full w-full object-cover object-center lg:hidden block mt-10"
          />
        </div>
      </section>
      <section id="name" className="lg:px-24 px-6 lg:mt-10 text-lg">
        <p className="text-lg lg:px-3 py-10">
          Don’t miss your chance to be part of the ultimate audiovisual
          experience at the National Home Show.
        </p>
        <p className="text-lg font-bold px-3">EVENT DETAILS</p>
        <img
          src="https://cdn.pmlsound.com/paradigm/NHS/24natjan19.svg"
          alt="plan"
        />
        <div className="text-lg lg:px-3 py-10">
          <p className="font-bold py-6">Visit us at Booth #3502</p>
          <p>
            <b>Location:</b> Enercare Centre | 100 Princes' Blvd #1, Toronto, ON
            M6K 3C3
          </p>
          <div className="space-y-1">
            <p className="font-bold pt-6">Show Hours:</p>
            <p>Friday, March 8: 10am - 8pm</p>
            <p>Saturday, March 9: 10am - 8pm</p>
            <p>Sunday, March 10: 10am - 5pm</p>
            <p>Monday, March 11: CLOSED</p>
            <p>Tuesday, March 12: CLOSED</p>
            <p>Wednesday, March 13: 10am - 8pm</p>
            <p>Thursday, March 14: 10am - 8pm</p>
            <p>Friday, March 15: 10am - 8pm</p>
            <p>Saturday, March 16: 10am - 8pm</p>
            <p>Sunday, March 17: 10am - 5pm</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NhsSocial;
