import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { ParadigmPromosData } from "../utils/ParadigmPromosData";
import moment from "moment";
import { appContext } from "../../../App";
import DocumentTitle from "../../../components/DocumentTitle";
import HorizontalScrollTabs from "../../../components/HorizontalScrollTabs";
import axios from "axios";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ParadigmPromosLandingPage = () => {
  const { country } = useContext(appContext);
  const today = new Date().toISOString().slice(0, 10);
  const [stockInfo, setStockInfo] = useState([]);

  // console.log(ParadigmPromosData);

  const skus = ParadigmPromosData.map((series) => {
    return series.products.map((product) => product.sku);
  });

  const filteredArray = skus
    .flat() // Flatten the array
    .filter((item) => item !== "");

  useEffect(() => {
    skus.length &&
      axios
        .post("https://backendapi.paradigm.com/api/get_quantity_data", {
          partIds: filteredArray,
        })
        .then((res) => setStockInfo(res.data.data))
        .catch((err) => console.log(err));
  }, []);

  const isAnyPromoActive = ParadigmPromosData.filter((item) => {
    if (
      moment() >= moment(item.startDate) &&
      moment() <= moment(item.endDate)
    ) {
      return true;
    } else {
      return false;
    }

    // return item;
  });

  // console.log(isAnyPromoActive);

  // const products = isAnyPromoActive.forEach((series) => {
  //   series.products.forEach((product) => {
  //     const stockItem = stockInfo.find((item) => item.PartId === product.sku);
  //     // if (stockItem) {
  //     //   product.flag = stockItem.flag;
  //     // }

  //     console.log(stockItem);
  //   });
  // });

  const products = isAnyPromoActive.map((seriesItem) => {
    return {
      ...seriesItem,
      products: seriesItem.products.map((product) => {
        // Find the matching item in the stock array based on SKU and PartId
        const stockItem = stockInfo.find((item) => item.PartId === product.sku);

        // If a match is found, use the flag from the stock array, otherwise default to 'in-stock'
        return {
          ...product,
          flag: stockItem ? stockItem.flag : "in-stock", // Default to 'in-stock' if no match
        };
      }),
    };
  });

  console.log(products);

  // const partQty = useWarehouseData(partIds);

  const calculateDiscountedPrice = (msrp, percentage) => {
    const afterDiscountPrice = (
      Number(msrp) -
      (Number(msrp) * Number(percentage)) / 100
    ).toFixed(2);

    return afterDiscountPrice;
  };
  // console.log(isAnyPromoActive);
  return (
    <>
      <DocumentTitle title={"Paradigm Promos"} />
      <div>
        <Header />
        <div id="banner" className="md:mt-14 mt-12 relative">
          {/* <img
            class="absolute top-0 left-0"
            src="https://cdn.pmlsound.com/paradigm/pe-promo-page-bkgd-graphic.jpg"
            alt="Workplace"
          />

          <img
            class="absolute top-0 xl:left-20 left-0 md:mt-6 xl:ml-[5.5rem]"
            src="https://cdn.pmlsound.com/paradigm/pe-promo-page-text-graphic.png"
            width="1500"
            alt="Workplace"
          /> */}

          {/* <img
            className="md:block hidden"
            src="https://cdn.pmlsound.com/paradigm/paradigm-holiday-sales-event-2023444.jpg"
            alt="Workplace"
          />
          <img
            className="md:hidden block"
            src="https://cdn.pmlsound.com/paradigm/paradigm-holiday-sales-event-2023-mobile.jpg"
            alt="Workplace"
          /> */}

          <img
            className=""
            src={
              today >= "2024-10-21" && today <= "2025-01-03"
                ? "https://cdn.pmlsound.com/paradigm/paradigm-holiday-sales-event-2024-PROMO-WEBPAGE.jpg"
                : today < "2024-10-21"
                ? "https://cdn.pmlsound.com/paradigm/SUBtacular-savings-promotions-page-banner-4.jpg"
                : "https://cdn.pmlsound.com/paradigm/pe-promo-page-bkgd-graphic.jpg"
            }
            // src="https://cdn.pmlsound.com/paradigm/SUBtacular-savings-promotions-page-banner-4.jpg"
            alt="Workplace"
          />
        </div>
        <section id="navigation" className="max-w-[76rem] mx-auto">
          <HorizontalScrollTabs data={isAnyPromoActive} />
        </section>
        <section id="body" className="bg-gray-100/10 relative pb-16">
          <div id="promo-body" className="max-w-[76rem] mx-auto pt-10 ">
            {products.length ? (
              products?.map((_, i) => {
                return (
                  <div className="py-4 lg:px-0 px-6" id={_.seriesHash}>
                    {_.seriesName === "Exclusive Close-Out Offers" &&
                    country === "CA" ? null : (
                      <div className="divide-y-8 divide-gray-100/10 xl:px-0 px-1 xl:py-6 py-6">
                        <p className="text-4xl font-bold">{_.seriesName}</p>
                        <p className="text-md font-light pr-6 max-w-xl">
                          {_.description}
                        </p>
                        {_.seriesName === "Factory Refurbished" ? (
                          <p>
                            <span className="font-bold text-3xl ">
                              {/* <span className="text-red-600">Sale Ends</span>{" "}
                            while supplies last. */}
                              Offer valid while supplies last
                            </span>
                          </p>
                        ) : (
                          <p className="text-3xl font-bold">
                            <span className="text-3xl text-red-600">
                              Sale Ends on
                            </span>{" "}
                            {/* {moment(_.endDate)
                            .subtract(1, "days")

                            .format("MMMM Do YYYY")} */}
                            {moment(_.endDate).format("MMMM Do YYYY")}
                          </p>
                        )}
                      </div>
                    )}

                    <div
                      id="products-list"
                      className="col-span-8 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6 xl:px-0"
                    >
                      {_.products.map((item, index) => {
                        const showItem =
                          (country === "CA" &&
                            item.discount_percentage_canada > 0) ||
                          (country === "US" && item.discount_percentage_us > 0);
                        return showItem ? (
                          <>
                            <div className="relative">
                              {moment() >= moment(item.startDate) &&
                              moment() <= moment(item.endDate) ? (
                                <>
                                  <div
                                    className="rounded-3xl border-solid border border-gray-200 shadow-md shadow-gray-500/40"
                                    key={item.id}
                                  >
                                    <div className="">
                                      <img
                                        src={item.imageURL}
                                        className={classNames(
                                          item.name === "Surround 3"
                                            ? "object-fill h-[285px] w-full"
                                            : "",
                                          "rounded-t-3xl bg-white p-3"
                                        )}
                                        alt="product_image"
                                      />
                                    </div>
                                    <div className="bg-gray-50 rounded-b-3xl">
                                      <h4
                                        className="text-3xl font-bold px-6 pt-6 pb-2"
                                        dangerouslySetInnerHTML={{
                                          __html: item.name,
                                        }}
                                      ></h4>
                                      <div className="text-base font-light leading-7 px-6 pb-6">
                                        <p className="flex gap-2">
                                          {country === "CA" ||
                                          country === "US" ? (
                                            <span className="text-red-600 font-bold text-xl">
                                              $
                                              {country === "CA"
                                                ? calculateDiscountedPrice(
                                                    item.msrp_canada,
                                                    item.discount_percentage_canada
                                                  )
                                                : calculateDiscountedPrice(
                                                    item.msrp_us,
                                                    item.discount_percentage_us
                                                  )}
                                            </span>
                                          ) : null}
                                          <span
                                            className={classNames(
                                              country === "CA" ||
                                                country === "US"
                                                ? "line-through"
                                                : "",
                                              "text-gray-900  font-medium text-md"
                                            )}
                                          >
                                            $
                                            {country === "CA"
                                              ? item.msrp_canada
                                              : item.msrp_us}
                                          </span>
                                        </p>
                                        {country === "CA" ||
                                        country === "US" ? (
                                          <span className="text-sm text-gray-600 font-medium">
                                            Save $
                                            {country === "CA"
                                              ? (
                                                  item.msrp_canada -
                                                  calculateDiscountedPrice(
                                                    item.msrp_canada,
                                                    item.discount_percentage_canada
                                                  )
                                                ).toFixed(2)
                                              : (
                                                  item.msrp_us -
                                                  calculateDiscountedPrice(
                                                    item.msrp_us,
                                                    item.discount_percentage_us
                                                  )
                                                ).toFixed(2)}{" "}
                                            <span className="font-light">
                                              (
                                              {country === "CA"
                                                ? `${item.discount_percentage_canada}% OFF`
                                                : `${item.discount_percentage_us}% OFF`}
                                              )
                                            </span>
                                          </span>
                                        ) : null}
                                      </div>
                                    </div>
                                    {/* {item.sku !== "" && ( */}
                                    <>
                                      <span className="sr-only">
                                        Notifications
                                      </span>
                                      <div className="capitalize absolute inline-flex items-center justify-center w-24 h-10 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-tr-2xl rounded-bl-2xl -top-0.5 -end-0.5 ">
                                        {item.flag}
                                      </div>
                                    </>
                                    {/* )} */}
                                  </div>

                                  <div class="cursor-pointer rounded-3xl opacity-0 hover:opacity-100 bg-gray-200 duration-300 absolute inset-0 z-10 gap-6 flex flex-col justify-center items-center text-xl text-black font-semibold">
                                    {country === "CA" || country === "US" ? (
                                      <a
                                        href={item.productPageUrl}
                                        target="_blank"
                                        className="cursor-pointer"
                                        rel="noreferrer"
                                      >
                                        {item.buttonLabel}
                                      </a>
                                    ) : null}

                                    {item.name !== "Monitor SE 8000F" &&
                                    !item.name.includes("Persona") &&
                                    !item.name.includes("RFB") ? (
                                      <a
                                        href="https://www.paradigm.com/en/dealer-locator"
                                        target="_blank"
                                        className="cursor-pointer"
                                        rel="noreferrer"
                                      >
                                        Find a Dealer
                                      </a>
                                    ) : null}
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </>
                        ) : null;
                      })}
                    </div>
                  </div>
                );
              })
            ) : (
              <h2 className="font-logo text-5xl font-medium text-center h-96">
                No promos are active at this time. Please check later.
              </h2>
            )}
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default ParadigmPromosLandingPage;
