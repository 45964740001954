import React from "react";
// import PopoverComponent from "../../../../../components/PopoverComponent";
import ToggleSwitch from "../../components/ToggleSwitch";
import { FieldArray } from "formik";

const tagCollection = [
  { value: "social media", label: "Social Media" },
  { value: "email newsletter", label: "Email newsletter" },
  { value: "advertisement", label: "Advertisement" },
  { value: "word of mouth", label: "Word of mouth" },
  { value: "other", label: "Other" },
];
const AwarenessSection = (props) => {
  const {
    formField: { familiar_with_martinlogan },
    steps,
    setFieldValue,
    values,
    faults,
    handleChange,
    errors,
    touched,
    initialValues,
    setValues,
    setFieldTouched,
    outOfBoxData,
  } = props;
  // console.log(values);
  return (
    <>
      <section className="px-6 lg:px-8" id="hero">
        <div className="max-w-2xl mx-auto text-gray-50">
          <h1 className="lg:text-5xl text-3xl font-bold">Awareness</h1>
          <p className="py-6 text-md font-light">
            Indicate your familiarity with MartinLogan before encountering our
            booth.
          </p>
          <p className="text-lg font-semibold">
            4. Were you familiar with MartinLogan before visiting one of our
            rooms at Audio Advice Live?
          </p>
          <div className="mt-6">
            <ToggleSwitch
              setFieldValue={setFieldValue}
              enabled={values.familiar_with_martinlogan}
              name={"familiar_with_martinlogan"}
            />
          </div>
          {/* <div className="mt-6">
            <p className="text-lg font-semibold">
              5. How did you hear about Paradigm x BBC Earth Viewing Lounge at
              the National Home Show? Select all that apply.
            </p>
            <FieldArray
              name="hear_about_nhs"
              render={(arrayHelpers) => (
                <div className="flex flex-col">
                  {tagCollection.map((tag) => (
                    <label key={tag.value} className="py-6 flex items-center">
                      <input
                        name="hear_about_nhs"
                        type="checkbox"
                        value={tag}
                        checked={values?.hear_about_nhs?.includes(tag.value)}
                        className="h-8 w-8 rounded border-yellow-500 text-yellow-500 focus:ring-yellow-500 dark:border-gray-400 dark:text-gray-400 dark:focus:ring-gray-400"
                        onChange={(e) => {
                          if (e.target.checked) {
                            arrayHelpers.push(tag.value);
                          } else {
                            const idx = values?.hear_about_nhs?.indexOf(
                              tag.value
                            );
                            arrayHelpers.remove(idx);
                          }
                        }}
                      />
                      <span className="text md:text-xl ml-6">{tag.label}</span>
                    </label>
                  ))}
                </div>
              )}
            />
            <div className="mt-3">
              {errors.hear_about_nhs && !values.hear_about_nhs.length ? (
                <div className="text-red-600 md:text-2xl text-lg">
                  Choose atleast one option
                </div>
              ) : null}
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default AwarenessSection;
