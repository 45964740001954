import * as Yup from "yup";

import checkoutFormModel from "./checkoutFormModel";
const {
  formField: {
    first_name_of_principle,
    last_name_of_principle,
    date_business_commenced,
    company_name,
    company_phone_number,
    company_email,
    company_website_url,
    total_annual_sales,
    number_of_employees,
    ownership_type,
    company_street_address,
    company_city,
    company_state,
    company_postal_code,
    company_country,
    other_authorized_speaker_brands,
    other_authorized_electronics_brands,
    control_automation_brands,
    percentage_annual_sales_audio,
  },
} = checkoutFormModel;

export const validationSchema = Yup.object().shape({
  [first_name_of_principle.name]: Yup.string()
    .required(`${first_name_of_principle.requiredErrorMsg}`)
    .min(2, "Must be more than 2 letters")
    .max(30, "Must be less than 30 letters"),
  [last_name_of_principle.name]: Yup.string()
    .required(`${last_name_of_principle.requiredErrorMsg}`)
    .min(2, "Must be more than 2 letters")
    .max(30, "Must be less than 30 letters"),
  [date_business_commenced.name]: Yup.string().required(
    `${date_business_commenced.requiredErrorMsg}`
  ),
  [company_name.name]: Yup.string().required(
    `${company_name.requiredErrorMsg}`
  ),
  [company_phone_number.name]: Yup.string()
    .required(`${company_phone_number.requiredErrorMsg}`)
    .min(10, "Must be 10 digits")
    .max(10, "Must be 10 digits"),
  [company_email.name]: Yup.string()
    .email("Company Email must be a valid email")
    .required(`${company_email.requiredErrorMsg}`),
  [company_website_url.name]: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    )
    .required(`${company_website_url.requiredErrorMsg}`),
  [total_annual_sales.name]: Yup.string().required(
    `${total_annual_sales.requiredErrorMsg}`
  ),
  [number_of_employees.name]: Yup.number()
    .typeError("You must specify a number")
    .min(1, "Min value 1")
    .required(`${number_of_employees.requiredErrorMsg}`),
  [ownership_type.name]: Yup.string().required(
    `${ownership_type.requiredErrorMsg}`
  ),
  [company_street_address.name]: Yup.string().required(
    `${company_street_address.requiredErrorMsg}`
  ),
  [company_city.name]: Yup.string().required(
    `${company_city.requiredErrorMsg}`
  ),
  [company_state.name]: Yup.string().required(
    `${company_state.requiredErrorMsg}`
  ),
  [company_postal_code.name]: Yup.string().required(
    `${company_postal_code.requiredErrorMsg}`
  ),
  [company_country.name]: Yup.string().required(
    `${company_country.requiredErrorMsg}`
  ),
  [percentage_annual_sales_audio.name]: Yup.string().required(
    `${percentage_annual_sales_audio.requiredErrorMsg}`
  ),
  // [other_authorized_speaker_brands.name]: Yup.array()
  //   .min(1)
  //   .required(`${other_authorized_speaker_brands.requiredErrorMsg}`),
  // [other_authorized_electronics_brands.name]: Yup.array()
  //   .min(1)
  //   .required(`${other_authorized_electronics_brands.requiredErrorMsg}`),
  // [control_automation_brands.name]: Yup.array()
  //   .min(1)
  //   .required(`${control_automation_brands.requiredErrorMsg}`),
});
