import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { AnthemPromosData } from "../utils/AnthemPromosData";
import moment from "moment";
import { appContext } from "../../../App";
import DocumentTitle from "../../../components/DocumentTitle";
import HorizontalScrollTabsAnthem from "../../../components/HorizontalScrollTabsAnthem";
import axios from "axios";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const AnthemPromosLandingPage = () => {
  const { country } = useContext(appContext);

  const today = new Date().toISOString().slice(0, 10);

  // console.log(today);
  // console.log(country);
  const [stockInfo, setStockInfo] = useState([]);

  // console.log(ParadigmPromosData);

  const skus = AnthemPromosData.map((series) => {
    return series.products.map((product) => product.sku);
  });

  const filteredArray = skus
    .flat() // Flatten the array
    .filter((item) => item !== "");

  useEffect(() => {
    skus.length &&
      axios
        .post("https://backendapi.paradigm.com/api/get_quantity_data", {
          partIds: filteredArray,
        })
        .then((res) => setStockInfo(res.data.data))
        .catch((err) => console.log(err));
  }, []);

  const isAnyPromoActive = AnthemPromosData.filter((item) => {
    if (
      moment() >= moment(item.startDate) &&
      moment() <= moment(item.endDate)
    ) {
      return true;
    } else {
      return false;
    }

    // return item;
  });

  const products = isAnyPromoActive.map((seriesItem) => {
    return {
      ...seriesItem,
      products: seriesItem.products.map((product) => {
        // Find the matching item in the stock array based on SKU and PartId
        const stockItem = stockInfo.find((item) => item.PartId === product.sku);

        // If a match is found, use the flag from the stock array, otherwise default to 'in-stock'
        return {
          ...product,
          flag: stockItem ? stockItem.flag : "in-stock", // Default to 'in-stock' if no match
        };
      }),
    };
  });

  // console.log(products);

  const calculateDiscountedPrice = (msrp, percentage) => {
    const afterDiscountPrice = (
      Number(msrp) -
      (Number(msrp) * Number(percentage)) / 100
    ).toFixed(2);

    return afterDiscountPrice;
  };

  // console.log(isAnyPromoActive);
  return (
    <>
      <DocumentTitle title={"Anthem Promos"} />
      <div>
        <Header />
        <div id="banner" className="pt-12">
          {/* <img
            className="md:block hidden"
            src="https://cdn.pmlsound.com/anthem/anthem-special-winter-sale-2023.jpg"
            alt="Workplace"
          />
          <img
            className="md:hidden block"
            src="https://cdn.pmlsound.com/anthem/anthem-special-winter-sale-2023-mobile.jpg"
            alt="Workplace"
          /> */}
          {/* <img
            className=""
            src="	https://cdn.pmlsound.com/anthem/anthem-promotions-header.jpg"
            alt="Workplace"
          /> */}
          {today <= "2025-01-03" && country === "CA" ? (
            <img
              className="pt-2"
              src="https://cdn.pmlsound.com/anthem/anthem-special-winter-sale-2024-WEBSITE-HOMEPAGE-CAN.jpg"
              alt="Workplace"
            />
          ) : (
            <img
              className="pt-2"
              src="https://cdn.pmlsound.com/anthem/anthem-special-winter-sale-2024-WEBSITE-HOMEPAGE-US.jpg"
              alt="Workplace"
            />
          )}
        </div>
        <section id="navigation" className="max-w-[76rem] mx-auto">
          <HorizontalScrollTabsAnthem data={isAnyPromoActive} />
        </section>
        <section id="body" className="py-20">
          <div id="promo-body" className="max-w-[1220px] mx-auto">
            {products.length ? (
              products?.map((_) => {
                return (
                  <div className="py-4 lg:px-0 px-6" id={_.seriesHash}>
                    <div
                      id="series-name"
                      className="divide-y-8 divide-gray-100/10 xl:px-0 px-6 xl:py-6 py-6"
                    >
                      <p className="text-4xl font-bold">{_.seriesName}</p>
                      <p className="text-md font-light pr-6 max-w-xl font-anthem">
                        {_.description}
                      </p>
                      {_.seriesName === "Factory Refurbished" ? (
                        <p>
                          <span className="font-bold font-anthem text-3xl ">
                            {/* <span className="text-red-600">Sale Ends</span>{" "}
                            while supplies last. */}
                            Offer valid while supplies last
                          </span>
                        </p>
                      ) : (
                        <p className="text-3xl font-bold font-anthem">
                          <span className=" text-3xl text-red-600">
                            Sale Ends on
                          </span>{" "}
                          {moment(_.endDate).format("MMMM Do YYYY")}
                        </p>
                      )}
                    </div>

                    <div
                      id="products-list"
                      className="col-span-8 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6 xl:px-0 px-6"
                    >
                      {_.products.map((item, index) => {
                        return (
                          <div className="relative">
                            {moment() >= moment(item.startDate) &&
                            moment() <= moment(item.endDate) ? (
                              <>
                                <div
                                  className="rounded-3xl border-solid border border-gray-200 shadow-md  shadow-gray-500/40"
                                  key={item.id}
                                >
                                  <div className="bg-gray-700 rounded-t-3xl">
                                    <img
                                      src={item.imageURL}
                                      className={classNames(
                                        // item.name === "P5 RFB"
                                        //   ? "object-fill h-36 w-full"
                                        //   : "",
                                        "rounded-t-3xl bg-gray-700 p-3"
                                      )}
                                      alt="product_image"
                                    />
                                  </div>
                                  <h4
                                    className="text-3xl font-bold px-6 pt-6 pb-2"
                                    dangerouslySetInnerHTML={{
                                      __html: item.name,
                                    }}
                                  ></h4>
                                  <div className="text-base font-light leading-7 px-6 pb-6">
                                    <p className="flex gap-2">
                                      {country === "CA" || country === "US" ? (
                                        <span className="text-red-600 font-bold text-xl">
                                          $
                                          {country === "CA"
                                            ? calculateDiscountedPrice(
                                                item.msrp_canada,
                                                item.discount_percentage_canada
                                              )
                                            : calculateDiscountedPrice(
                                                item.msrp_us,
                                                item.discount_percentage_us
                                              )}
                                        </span>
                                      ) : null}
                                      <span
                                        className={classNames(
                                          country === "CA" || country === "US"
                                            ? "line-through"
                                            : "",
                                          "text-gray-900  font-medium text-md"
                                        )}
                                      >
                                        $
                                        {country === "CA"
                                          ? item.msrp_canada
                                          : item.msrp_us}
                                      </span>
                                    </p>
                                    {country === "CA" || country === "US" ? (
                                      <span className="text-sm text-gray-600 font-medium">
                                        Save $
                                        {country === "CA"
                                          ? (
                                              item.msrp_canada -
                                              calculateDiscountedPrice(
                                                item.msrp_canada,
                                                item.discount_percentage_canada
                                              )
                                            ).toFixed(2)
                                          : (
                                              item.msrp_us -
                                              calculateDiscountedPrice(
                                                item.msrp_us,
                                                item.discount_percentage_us
                                              )
                                            ).toFixed(2)}{" "}
                                        <span className="font-light">
                                          (
                                          {country === "CA"
                                            ? `${item.discount_percentage_canada}% OFF`
                                            : `${item.discount_percentage_us}% OFF`}
                                          )
                                        </span>
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                                {/* {item.sku !== "" && ( */}
                                <>
                                  {" "}
                                  <span className="sr-only">Notifications</span>
                                  <div className="capitalize absolute inline-flex items-center justify-center w-24 h-8 text-xs font-bold text-white bg-red-500 border-1 border-white rounded-tr-2xl rounded-bl-2xl -top-0.5 -end-0.5 dark:border-gray-900">
                                    {item.flag}
                                  </div>
                                </>
                                {/* )} */}
                                <div class="cursor-pointer rounded-3xl opacity-0 hover:opacity-100 bg-gray-200 duration-300 absolute inset-0 z-10 gap-6 flex flex-col justify-center items-center text-xl text-black font-semibold">
                                  {country === "CA" || country === "US" ? (
                                    <a
                                      href={item.productPageUrl}
                                      target="_blank"
                                      className="cursor-pointer"
                                      rel="noreferrer"
                                    >
                                      {item.buttonLabel}
                                    </a>
                                  ) : null}
                                  {item.name !== "AVM 90" &&
                                  !item.name.includes("RFB") ? (
                                    <a
                                      href="https://www.anthemav.com/locator/index.php"
                                      target="_blank"
                                      className="cursor-pointer"
                                      rel="noreferrer"
                                    >
                                      Find a Dealer
                                    </a>
                                  ) : null}
                                </div>
                              </>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })
            ) : (
              <h2 className="font-anthem text-5xl font-medium text-center h-96">
                No promos are active at this time. Please check later.
              </h2>
            )}
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default AnthemPromosLandingPage;
