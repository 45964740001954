import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function LearnMoreSuccess() {
  const navigate = useNavigate();

  const month = moment(new Date()).format("M");
  const day = moment(new Date()).format("DD");
  // console.log(month, day);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <section className="px-6 lg:px-8" id="hero">
        <div className="max-w-7xl mx-auto text-gray-50">
          <p className="py-6 text-2xl text-center font-logo leading-tight">
            Thank you for taking the time to complete our survey! Your feedback
            is greatly appreciated, and we wish you the best of luck in the
            contest!
          </p>
          {month === "3" &&
          (day === "08" ||
            day === "09" ||
            day === "10" ||
            day === "11" ||
            day === "12" ||
            day === "13") ? (
            <p className="py-1 text-md font-light text-center leading-7">
              As a token of our appreciation, Print or screenshot this page and
              contact Audio One
              <a href="https://www.audio-one.ca" className="underline">
                {" "}
                (https://www.audio-one.ca/)
              </a>{" "}
              for a special <br />
              <span className="bg-red-500 px-1 py-0.5 font-semibold">
                15% OFF
              </span>{" "}
              on Paradigm or Anthem products and a{" "}
              <span className="bg-red-500 px-1 py-0.5 font-semibold">
                20% OFF
              </span>{" "}
              on Paradigm Outdoor products. <br />
              <br />{" "}
              <b className="font-semibold">
                Paradigm/Anthem, offer valid until April 30
              </b>{" "}
              <br />{" "}
              <b className="font-semibold">
                Paradigm Outdoor, offer valid until June 30
              </b>
            </p>
          ) : month === "3" &&
            (day === "14" || day === "15" || day === "16" || day === "17") ? (
            <p className="py-1 text-md font-light text-center leading-7">
              As a token of our appreciation, Print or screenshot this page and
              contact Trutone
              <a href="https://www.audio-one.ca" className="underline">
                {" "}
                (https://www.trutone.ca/)
              </a>{" "}
              for a special <br />
              <span className="bg-red-500 px-1 py-0.5 font-semibold">
                15% OFF
              </span>{" "}
              on Paradigm or Anthem products and a{" "}
              <span className="bg-red-500 px-1 py-0.5 font-semibold">
                20% OFF
              </span>{" "}
              on Paradigm Outdoor products. <br />
              <br />{" "}
              <b className="font-semibold">
                Paradigm/Anthem, offer valid until April 30
              </b>{" "}
              <br />{" "}
              <b className="font-semibold">
                Paradigm Outdoor, offer valid until June 30
              </b>
            </p>
          ) : null}
        </div>
      </section>
      <div className="max-w-sm mx-auto px-8">
        <a
          className="mt-16 btn-next text text-gray-50 py-1"
          href="https://www.paradigm.com/en/"
        >
          Explore Paradigm
        </a>
      </div>
    </React.Fragment>
  );
}

export default LearnMoreSuccess;
