import React, { useContext } from "react";
import { FieldArray } from "formik";
// import PopoverComponent from "../../../../../components/PopoverComponent";
import ToggleSwitch from "../../components/ToggleSwitch";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tagCollection = [
  { value: "local retailer", label: "Local big box retailer" },
  { value: "local audio shop", label: "Local independent audio shop" },
  { value: "online", label: "Online" },
  {
    value: "second-hand",
    label: "Second-hand (Facebook Marketplace, Craigslist, etc.)",
  },
];

const AudioAffinitySection = (props) => {
  const {
    formField: {
      planning_purchase_six_months,
      purchase_location,
      adding_audio_renovation,
      interested_in_outdoor_audio,
      watch_pe_bbc,
    },
    steps,
    setFieldValue,
    values,
    faults,
    handleChange,
    errors,
    touched,
    outOfBoxData,
  } = props;

  // console.log(values);

  return (
    <section className="px-6 lg:px-8" id="hero">
      <div className="max-w-7xl mx-auto text-gray-50">
        <h1 className="lg:text-5xl text-3xl font-bold">Audio Affinity</h1>
        <p className="py-6 text-md font-light">
          Explore what might pique your interest.
        </p>
        <p className="text-lg font-semibold">
          9. Do you plan on watching Planet Earth 3 on BBC Earth?
        </p>
        <div className="mt-6">
          <ToggleSwitch
            setFieldValue={setFieldValue}
            enabled={values.watch_pe_bbc}
            name={"watch_pe_bbc"}
          />
        </div>
        <p className="text-lg font-semibold">
          10. Are you considering adding an audio component in your next home
          renovation?
        </p>
        <div className="mt-6">
          <ToggleSwitch
            setFieldValue={setFieldValue}
            enabled={values.adding_audio_renovation}
            name={"adding_audio_renovation"}
          />
        </div>

        <p className="text-lg font-semibold mt-4">
          11. Are you interested in outdoor audio solutions?
        </p>
        <div className="mt-6">
          <ToggleSwitch
            setFieldValue={setFieldValue}
            enabled={values.interested_in_outdoor_audio}
            name={"interested_in_outdoor_audio"}
          />
        </div>

        <p className="text-lg font-semibold mt-4">
          12. Are you planning to purchase Paradigm products within the next
          6-12 months?
        </p>
        <div className="mt-6">
          <ToggleSwitch
            setFieldValue={setFieldValue}
            enabled={values.planning_purchase_six_months}
            name={"planning_purchase_six_months"}
          />
        </div>

        {values.planning_purchase_six_months && (
          <div className="mt-6">
            <p className="text-lg font-semibold">
              13. Where are you planning to purchase Paradigm products within
              the next 6-12 months?
            </p>
            <FieldArray
              name="purchase_location"
              render={(arrayHelpers) => (
                <div className="flex flex-col">
                  {tagCollection.map((tag) => (
                    <label key={tag.value} className="py-6 flex items-center">
                      <input
                        name="purchase_location"
                        type="checkbox"
                        value={tag}
                        checked={values?.purchase_location?.includes(tag.value)}
                        className="h-8 w-8 rounded border-yellow-500 text-yellow-500 focus:ring-yellow-500 dark:border-gray-400 dark:text-gray-400 dark:focus:ring-gray-400"
                        onChange={(e) => {
                          if (e.target.checked) {
                            arrayHelpers.push(tag.value);
                          } else {
                            const idx = values?.purchase_location?.indexOf(
                              tag.value
                            );
                            arrayHelpers.remove(idx);
                          }
                        }}
                      />
                      <span className="text md:text-xl ml-6">{tag.label}</span>
                    </label>
                  ))}
                </div>
              )}
            />
            <div className="mt-3">
              {errors.purchase_location && !values.purchase_location.length ? (
                <div className="text-red-600 md:text-2xl text-lg">
                  Choose atleast one option
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default AudioAffinitySection;
