export default {
  formId: "checkoutForm",
  formField: {
    title_of_principle: {
      name: "title_of_principle",
      label: "Title of Principle",
      // requiredErrorMsg: "Title of Principle is required.",
    },
    first_name_of_principle: {
      name: "first_name_of_principle",
      label: "First Name of Principle*",
      requiredErrorMsg: "First Name of Principle is required.",
    },
    last_name_of_principle: {
      name: "last_name_of_principle",
      label: "Last Name of Principle*",
      requiredErrorMsg: "Last Name of Principle is required.",
    },
    date_business_commenced: {
      name: "date_business_commenced",
      label: "Date Business Commenced*",
      requiredErrorMsg: "Date Business Commenced is required",
    },
    company_name: {
      name: "company_name",
      label: "Company Name*",
      requiredErrorMsg: "Company Name is required",
    },
    company_phone_number: {
      name: "company_phone_number",
      label: "Phone Number*",
      requiredErrorMsg: "Phone Number is required",
    },
    company_email: {
      name: "company_email",
      label: "Company Email*",
      requiredErrorMsg: "Company Email is required",
    },
    company_website_url: {
      name: "company_website_url",
      label: "Company Website URL*",
      requiredErrorMsg: "Company Website URL is required",
    },
    total_annual_sales: {
      name: "total_annual_sales",
      label: "Total Annual Sales*",
      requiredErrorMsg: "Total Annual Sales is required",
    },
    number_of_employees: {
      name: "number_of_employees",
      label: "Number Of Employees*",
      requiredErrorMsg: "Number Of Employees is required",
    },
    ownership_type: {
      name: "ownership_type",
      label: "Ownership Type*",
      requiredErrorMsg: "Ownership Type is required",
    },
    company_street_address: {
      name: "company_street_address",
      label: "Street*",
      requiredErrorMsg: "Street Address is required",
    },
    company_unit: {
      name: "company_unit",
      label: "Unit",
      // requiredErrorMsg: "Unit is required",
    },
    company_city: {
      name: "company_city",
      label: "City*",
      requiredErrorMsg: "City is required",
    },
    company_state: {
      name: "company_state",
      label: "State/Province*",
      requiredErrorMsg: "State is required",
    },
    company_postal_code: {
      name: "company_postal_code",
      label: "Postal Code/Zip Code*",
      requiredErrorMsg: "Zip/Postal Code is required",
    },
    company_country: {
      name: "company_country",
      label: "Country*",
      requiredErrorMsg: "Country is required",
    },
    percentage_annual_sales_audio: {
      name: "percentage_annual_sales_audio",
      label: "Percentage of Annual Sales Related to Audio*",
      requiredErrorMsg: "You need to choose atleast one.",
    },
    other_authorized_speaker_brands: {
      name: "other_authorized_speaker_brands",
      label: "Other Authorized Speaker or Subwoofer Brands",
    },
    other_authorized_electronics_brands: {
      name: "other_authorized_electronics_brands",
      label: "Other Authorized Electronic Brands",
    },
    control_automation_brands: {
      name: "control_automation_brands",
      label: "Control/Automation Brands",
    },
  },
};
