import React, { useState } from "react";
import { MultiSelectField } from "./MultiSelectField";

const options = [
  { label: "Sunday", value: "sunday" },
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednessday", value: "wednessday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
  { label: "Week", value: "week", disabled: true },
];

const SelectFields = ({ name }) => {
  return <MultiSelectField options={options} labelledBy="Select" name={name} />;
};

export default SelectFields;
