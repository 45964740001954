export default {
  formId: "checkoutForm",
  formField: {
    hear_about_us: {
      name: "hear_about_us",
      requiredErrorMsg: "Choose atleast one",
    },
    hear_about_us_other: {
      name: "hear_about_us_other",
      requiredErrorMsg:
        "Please specify what attracted you to attend Audio Advice Live this year?",
    },

    age: {
      name: "age",
      requiredErrorMsg: "Age is required",
    },
    // gender: {
    //   name: "gender",
    //   requiredErrorMsg: "Gender is required",
    // },
    // gender_other: {
    //   name: "gender_other",
    //   requiredErrorMsg: "Please specify your gender",
    // },
    familiar_with_martinlogan: {
      name: "familiar_with_martinlogan",
      requiredErrorMsg: "Choose atleast one.",
    },
    rate_overall_experience: {
      name: "rate_overall_experience",
      requiredErrorMsg:
        "Please rate your overall experience at our booth and with our product demo",
    },
    consider_purchasing_martinlogan: {
      name: "consider_purchasing_martinlogan",
      requiredErrorMsg:
        "Please tell us if you would consider purchasing MartinLogan after experiencing the product demo",
    },
    recommend_martinlogan: {
      name: "recommend_martinlogan",
      requiredErrorMsg: "Would you recommend MartinLogan is required",
    },
    contact_for_promotions: {
      name: "contact_for_promotions",
      requiredErrorMsg: "Would you like to hear from MartinLogan in the future",
    },
    planning_purchase_six_months: {
      name: "planning_purchase_six_months",
      requiredErrorMsg:
        "Please tell us if you planning to purchase MartinLogan products within the next 6-12 months",
    },
    name: {
      name: "name",
      requiredErrorMsg: "Name is required",
    },
    email: {
      name: "email",
      requiredErrorMsg: "Email is required",
    },
    phone_number: {
      name: "phone_number",
      requiredErrorMsg: "Phone Number is required",
    },
  },
};
