import React, { useEffect, useState } from "react";
import RadioGroupComponent from "../../components/RadioGroupComponent";
import InputField from "../../components/FormFields/InputField";
import PopoverComponent from "../../components/PopoverComponent";
// import Decor1SC from "../../../../../components/Decor1SC";

// import PopoverComponent from "../../../../../components/PopoverComponent";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const AboutYou = (props) => {
  const options = [
    {
      title: "Consumer / Homeowner",
      value: "Consumer/Homeowner",
    },
    {
      title: "Audio/Video Retailer",
      value: "Audio/Video Retailer",
    },
    {
      title: "Architect",
      value: "Architect",
    },
    {
      title: "Interior Designer",
      value: "Interior Designer",
    },
    {
      title: "Real Estate Professional",
      value: "Real Estate Professional",
    },
    {
      title: "Supplier / Manufacturer",
      value: "Supplier/Manufacturer",
    },
    {
      title: "Trade professional",
      value: "Trade professional",
    },
    {
      title: "Educator / Student",
      value: "Educator/Student",
    },
    {
      title: "Media/Press",
      value: "Media/Press",
    },
    {
      title: "Other",
      value: "other",
    },
  ];

  const ageOptions = [
    {
      title: "Under 18",
      value: "Under 18",
    },
    {
      title: "18-24",
      value: "18-24",
    },
    {
      title: "25-34",
      value: "25-34",
    },
    {
      title: "35-44",
      value: "35-44",
    },
    {
      title: "45-54",
      value: "45-54",
    },
    {
      title: "55-64",
      value: "55-64",
    },
    {
      title: "65 or over",
      value: "65 or over",
    },
  ];

  const genderOptions = [
    { title: "Man", value: "man" },
    { title: "Woman", value: "woman" },
    { title: "Non-binary", value: "non-binary" },
    { title: "Prefer not to say", value: "prefer not to say" },
    { title: "Prefer to self-describe", value: "other_gender" },
  ];

  const {
    formField: {
      role_within_home_industry,
      role_within_home_industry_other,
      age,
      gender,
      gender_other,
    },
    steps,
    setFieldValue,
    values,
    faults,
    handleChange,
    errors,
    touched,
    outOfBoxData,
  } = props;

  // console.log(values);

  return (
    <>
      <section className="px-6 lg:px-8" id="hero">
        <div className="max-w-7xl mx-auto text-gray-50">
          <p className="pb-6 text-md font-light">
            Thank you so much for visiting the Paradigm x BBC Earth Viewing
            Lounge at the National Home Show, and for taking the time to leave
            feedback about your experience! By completing this survey, you also
            get a chance to win a fantastic prize. This survey will take about 3
            minutes.
          </p>
          <div>
            <p className="text-lg font-semibold">
              1. Please select the option that best describes your role or
              affiliation within the home industry:
            </p>
            <div className="mt-6 grid grid-cols-2 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 gap-4">
              {options.map((_, i) => {
                return (
                  <div key={_.title} className="flex-cols items-center">
                    {i === 6 ? (
                      <div className="flex justify-end ">
                        <PopoverComponent
                          title={""}
                          description={
                            "Contractor, builder, plumber, electrician, etc."
                          }
                        />
                      </div>
                    ) : i === 7 ? (
                      <div className="flex justify-end ">
                        <PopoverComponent
                          title={""}
                          description={
                            "Studying fields related to architecture, interior design, construction, or a related discipline"
                          }
                        />
                      </div>
                    ) : null}
                    <div
                      className={classNames(i === 6 || i === 7 ? "-mt-6" : "")}
                    >
                      <input
                        className="hidden"
                        id={_.value}
                        type="radio"
                        name="radio"
                        onChange={(e) => {
                          // console.log(e.target.id);
                          setFieldValue(
                            "role_within_home_industry",
                            e.target.id
                          );
                        }}
                        checked={values.role_within_home_industry === _.value}
                      />

                      <label
                        className="btn-province-small cursor-pointer text-center"
                        htmlFor={_.value}
                      >
                        {_.title}
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="mt-3">
              {errors.role_within_home_industry &&
              !values.role_within_home_industry ? (
                <div className="text-red-600 md:text-2xl text-lg">
                  Choose one option
                </div>
              ) : null}
            </div>
          </div>
          {values.role_within_home_industry === "other" ? (
            <div className="mt-10">
              <InputField
                name={role_within_home_industry_other.name}
                label={role_within_home_industry_other.label}
                placeholder="Please specify your role within the audio industry"
              />
            </div>
          ) : null}
          <div className="mt-6">
            <p className="text-lg font-semibold">2. What is your age?</p>
            <div className="mt-6 grid grid-cols-2 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-8 gap-4">
              {ageOptions.map((_) => {
                return (
                  <div key={_.title}>
                    <input
                      className="hidden"
                      id={_.value}
                      type="radio"
                      name="ageRadio"
                      onChange={(e) => {
                        // console.log(e.target.id);
                        setFieldValue("age", e.target.id);
                      }}
                      checked={values.age === _.value}
                    />
                    <label
                      className="btn-province-small cursor-pointer text-center"
                      htmlFor={_.value}
                    >
                      {_.title}
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="mt-3">
              {errors.age && !values.age ? (
                <div className="text-red-600 md:text-2xl text-lg">
                  Choose one option
                </div>
              ) : null}
            </div>
          </div>

          <div className="mt-6">
            <p className="text-lg font-semibold">3. How do you identify?</p>
            <div className="mt-6 grid grid-cols-2 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-8 gap-4">
              {genderOptions.map((_) => {
                return (
                  <div key={_.title}>
                    <input
                      className="hidden"
                      id={_.value}
                      type="radio"
                      name="genderRadio"
                      onChange={(e) => {
                        // console.log(e.target.id);
                        setFieldValue("gender", e.target.id);
                      }}
                      checked={values.gender === _.value}
                    />
                    <label
                      className="btn-province-small cursor-pointer text-center"
                      htmlFor={_.value}
                    >
                      {_.title}
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="mt-3">
              {errors.gender && !values.gender ? (
                <div className="text-red-600 md:text-2xl text-lg">
                  Choose one option
                </div>
              ) : null}
            </div>
          </div>
          {values.gender === "other_gender" ? (
            <div className="mt-10">
              <InputField
                name={gender_other.name}
                label={gender_other.label}
                placeholder="How do you identify?"
              />
            </div>
          ) : null}
        </div>
      </section>
    </>
  );
};

export default AboutYou;
