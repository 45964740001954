import React from "react";

export const Alert = (props) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  let d = {
    success:
      "M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z",
    error:
      "M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z",
  };
  return (
    <div
      className={classNames(
        props?.status === "error" ? "bg-red-50" : "bg-green-50",
        "rounded-md p-4"
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <svg
            className={classNames(
              props?.status === "error" ? "text-red-400" : "text-green-400",
              "h-5 w-5"
            )}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d={props?.status === "error" ? d.error : d.success}
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3">
          <h3
            className={classNames(
              props?.status === "error" ? "text-red-800" : "text-green-800",
              "text-md font-semibold"
            )}
          >
            {props?.title}
          </h3>
          <div
            className={classNames(
              props?.status === "error" ? "text-red-700" : "text-green-700",
              "mt-2 text-sm"
            )}
          >
            <ul className="list-disc pl-5 space-y-1">
              {/* {props?.message.map((value, id) => {
                if (typeof value === "object") {
                  return (
                    <li key={id}>
                      <p className="font-medium" style={{ display: "inline" }}>
                        {props?.formField[Object.keys(value)]?.label}:
                      </p>{" "}
                      <p style={{ display: "inline" }}>
                        {value[Object.keys(value)]}
                      </p>
                    </li>
                  );
                } else {
                  return <li key={id}>{value}</li>;
                }
              })} */}
              <li>
                <p className="font-medium" style={{ display: "inline" }}>
                  {props?.message}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
