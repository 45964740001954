import React, { useEffect, useState } from "react";
import RadioGroupComponent from "../../components/RadioGroupComponent";
import InputField from "../../components/FormFields/InputField";
import PopoverComponent from "../../components/PopoverComponent";
import { FieldArray } from "formik";
// import Decor1SC from "../../../../../components/Decor1SC";

// import PopoverComponent from "../../../../../components/PopoverComponent";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const AboutYou = (props) => {
  const options = [
    {
      title: "Consumer / Homeowner",
      value: "Consumer/Homeowner",
    },
    {
      title: "Audio/Video Retailer",
      value: "Audio/Video Retailer",
    },
    {
      title: "Architect",
      value: "Architect",
    },
    {
      title: "Interior Designer",
      value: "Interior Designer",
    },
    {
      title: "Real Estate Professional",
      value: "Real Estate Professional",
    },
    {
      title: "Supplier / Manufacturer",
      value: "Supplier/Manufacturer",
    },
    {
      title: "Trade professional",
      value: "Trade professional",
    },
    {
      title: "Educator / Student",
      value: "Educator/Student",
    },
    {
      title: "Media/Press",
      value: "Media/Press",
    },
    {
      title: "Other",
      value: "other",
    },
  ];

  const tagCollection = [
    {
      value: "Instagram",
      label: "Instagram",
    },
    {
      value: "Facebook",
      label: "Facebook",
    },
    {
      value: "Twitter",
      label: "Twitter",
    },
    {
      value: "LinkedIn",
      label: "LinkedIn",
    },
    { value: "other", label: "Other" },
  ];

  const ageOptions = [
    {
      title: "Under 18",
      value: "Under 18",
    },
    {
      title: "18-24",
      value: "18-24",
    },
    {
      title: "25-34",
      value: "25-34",
    },
    {
      title: "35-44",
      value: "35-44",
    },
    {
      title: "45-54",
      value: "45-54",
    },
    {
      title: "55-64",
      value: "55-64",
    },
    {
      title: "65 or over",
      value: "65 or over",
    },
  ];

  const genderOptions = [
    { title: "Man", value: "man" },
    { title: "Woman", value: "woman" },
    { title: "Non-binary", value: "non-binary" },
    { title: "Prefer not to say", value: "prefer not to say" },
    { title: "Prefer to self-describe", value: "other_gender" },
  ];

  const {
    formField: {
      hear_about_us,
      hear_about_us_other,
      age,
      gender,
      gender_other,
    },
    steps,
    setFieldValue,
    values,
    faults,
    handleChange,
    errors,
    touched,
    outOfBoxData,
  } = props;

  // console.log(values);

  return (
    <>
      <section className="px-6 lg:px-8" id="hero">
        <div className="max-w-2xl mx-auto text-gray-50">
          <p className="pb-6 text-md font-light">
            Thank you for participating in our Social Media Giveaway! By
            completing this survey, you also get a chance to win MartinLogan’s
            exciting new innovation – the Dynamo 10 Subwoofer*. This survey will
            take about 2 minutes.
          </p>
          <p className="italic">
            *Please note: Prize fulfillment will occur in late October 2024.
          </p>

          <div className="mt-6">
            <p className="text-lg font-semibold">
              1. How did you hear about this giveaway?
            </p>
            <FieldArray
              name="hear_about_us"
              render={(arrayHelpers) => (
                <div className="flex flex-col pt-6">
                  {tagCollection.map((tag) => (
                    <label key={tag.value} className="py-2 flex items-center">
                      <input
                        name="hear_about_us"
                        type="checkbox"
                        value={tag}
                        checked={values?.hear_about_us?.includes(tag.value)}
                        className="h-8 w-8 rounded border-[#d59028] text-[#d59028] focus:ring-[#d59028] dark:border-gray-400 dark:text-gray-400 dark:focus:ring-gray-400"
                        onChange={(e) => {
                          if (e.target.checked) {
                            arrayHelpers.push(tag.value);
                          } else {
                            const idx = values?.hear_about_us?.indexOf(
                              tag.value
                            );
                            arrayHelpers.remove(idx);
                          }
                        }}
                      />
                      <span className="text md:text-xl ml-6">{tag.label}</span>
                    </label>
                  ))}
                </div>
              )}
            />
            <div className="mt-3">
              {errors.hear_about_us && !values.hear_about_us.length ? (
                <div className="text-red-600 md:text-2xl text-lg">
                  Choose atleast one option
                </div>
              ) : null}
            </div>

            {values.hear_about_us?.includes("other") ? (
              <div className="mt-10">
                <InputField
                  name={hear_about_us_other.name}
                  label={hear_about_us_other.label}
                  placeholder={hear_about_us_other.requiredErrorMsg}
                />
              </div>
            ) : null}
          </div>
          <div className="mt-6">
            <p className="text-lg font-semibold">2. What is your age?</p>
            <div className="mt-6 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-4">
              {ageOptions.map((_) => {
                return (
                  <div key={_.title}>
                    <input
                      className="hidden"
                      id={_.value}
                      type="radio"
                      name="ageRadio"
                      onChange={(e) => {
                        // console.log(e.target.id);
                        setFieldValue("age", e.target.id);
                      }}
                      checked={values.age === _.value}
                    />
                    <label
                      className="btn-province-small cursor-pointer text-center border-[#d59028] hover:bg-[#d59028]"
                      htmlFor={_.value}
                    >
                      {_.title}
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="mt-3">
              {errors.age && !values.age ? (
                <div className="text-red-600 md:text-2xl text-lg">
                  Choose one option
                </div>
              ) : null}
            </div>
          </div>

          {/* <div className="mt-6">
            <p className="text-lg font-semibold">3. How do you identify?</p>
            <div className="mt-6 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-4">
              {genderOptions.map((_) => {
                return (
                  <div key={_.title}>
                    <input
                      className="hidden"
                      id={_.value}
                      type="radio"
                      name="genderRadio"
                      onChange={(e) => {
                        // console.log(e.target.id);
                        setFieldValue("gender", e.target.id);
                      }}
                      checked={values.gender === _.value}
                    />
                    <label
                      className="btn-province-small cursor-pointer text-center border-[#d59028] hover:bg-[#d59028]"
                      htmlFor={_.value}
                    >
                      {_.title}
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="mt-3">
              {errors.gender && !values.gender ? (
                <div className="text-red-600 md:text-2xl text-lg">
                  Choose one option
                </div>
              ) : null}
            </div>
          </div>
          {values.gender === "other_gender" ? (
            <div className="mt-10">
              <InputField
                name={gender_other.name}
                label={gender_other.label}
                placeholder="How do you identify?"
              />
            </div>
          ) : null} */}
        </div>
      </section>
    </>
  );
};

export default AboutYou;
