import { useState } from "react";
import { Switch } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ToggleSwitch(props) {
  //   console.log(props);
  //   const [enabled, setEnabled] = useState(false);

  const pathname = window.location.href.includes("paradigm")
    ? "paradigm"
    : window.location.href.includes("anthem")
    ? "anthem"
    : "martinlogan";

  const handleChange = (a) => {
    props.setFieldValue(props.name, a);
  };

  return (
    <div className="flex">
      <span className="grow-0 h-14 mt-2 mr-3">NO</span>
      <Switch
        checked={props.enabled}
        onChange={handleChange}
        className={classNames(
          props.enabled && pathname === "martinlogan"
            ? "bg-[#d59028] focus:ring-green-400"
            : props.enabled && pathname !== "martinlogan"
            ? "bg-green-600 focus:ring-yellow-400"
            : "bg-red-600 focus:ring-red-400",
          "relative grow-0 inline-flex h-10 w-24 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-4  focus:ring-offset-1"
        )}
      >
        <span className="sr-only">Use setting</span>

        <span
          className={classNames(
            props.enabled ? "translate-x-14" : "translate-x-0",
            "pointer-events-none relative inline-block h-9 w-9 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        >
          <span
            className={classNames(
              props.enabled
                ? "opacity-0 duration-100 ease-out"
                : "opacity-100 duration-200 ease-in",
              "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
            )}
            aria-hidden="true"
          >
            <svg
              className="h-6 w-6 text-red-600"
              fill="none"
              viewBox="0 0 12 12"
            >
              <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span
            className={classNames(
              props.enabled
                ? "opacity-100 duration-200 ease-in"
                : "opacity-0 duration-100 ease-out",
              "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
            )}
            aria-hidden="true"
          >
            <svg
              className="h-6 w-6 text-green-600"
              fill="currentColor"
              viewBox="0 0 12 12"
            >
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
            </svg>
          </span>
        </span>
      </Switch>
      <span className="grow-0 h-14 mt-2 ml-3">YES</span>
    </div>
  );
}
