import { useState, Fragment } from "react";
import { RadioGroup } from "@headlessui/react";
import { Popover, Transition } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const plans = [
  {
    name: "Startup",
    ram: "12GB",
    cpus: "6 CPUs",
    disk: "160 GB SSD disk",
  },
  {
    name: "Business",
    ram: "16GB",
    cpus: "8 CPUs",
    disk: "512 GB SSD disk",
  },
  {
    name: "Enterprise",
    ram: "32GB",
    cpus: "12 CPUs",
    disk: "1024 GB SSD disk",
  },
];

export default function RadioGroupComponent(props) {
  const [selected, setSelected] = useState(plans[0]);

  const handleChange = (a, b) => {
    props.setFieldValue(props.name, a.title);
  };

  return (
    <div className="w-full px-4 py-6">
      <div className="mx-auto w-full max-w-7xl">
        <RadioGroup value={props.selected} onChange={handleChange}>
          <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>

          <div className={classNames(`${props.classes}`, "grid gap-6")}>
            {props.options.map((_) => (
              <RadioGroup.Option
                key={_.title}
                value={_}
                className={({ active, checked }) =>
                  `${
                    active
                      ? "ring-2 ring-yellow-400/80 ring-offset-2 dark:ring-offset-yellow-100"
                      : "bg-[#D9D9D9] border-yellow-500 border-2"
                  }
                  ${checked ? "bg-yellow-400 text-gray-900" : "bg-white"}
                    relative flex cursor-pointer rounded-lg px-2 py-3 shadow-md focus:outline-none`
                }
              >
                {({ active, checked, show }) => (
                  <>
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-medium ${
                              checked ? "text-gray-900/75" : "text-gray-900"
                            }`}
                          >
                            {_.title}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={`inline ${
                              checked ? "text-gray-900/75" : "text-gray-500"
                            }`}
                          >
                            {/* <span className="text-sm">{_.subtitle}</span> */}
                            {/* <span aria-hidden="true">&middot;</span>{" "} */}
                            {/* <span>{plan.disk}</span> */}
                          </RadioGroup.Description>
                        </div>
                      </div>
                      {/* {checked && (
                        <div className="shrink-0 text-white">
                          <CheckIcon className="h-6 w-6" />
                        </div>
                      )} */}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  );
}

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.4" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
