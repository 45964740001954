import React from "react";
import { at } from "lodash";
import { useField } from "formik";

import { Formik, Field, Form, ErrorMessage, useFormik } from "formik";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { CheckCircleIcon } from "@heroicons/react/solid";
export default function InputField(props) {
  const { errorText, setFieldValue, setField, ...rest } = props;
  const [field, meta] = useField(props);

  function _renderHelperText() {
    const [touched, error] = at(meta, "touched", "error");
    console.log(error);
    if (touched && error) {
      return error;
    }
  }

  return (
    <>
      <label className="block text-sm font-medium text-gray-700">
        {rest.label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <Field
          type="text"
          {...field}
          {...rest}
          className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md  ${
            meta.error && meta.touched
              ? "focus:border-red-500 focus:ring-red-500 border-red-500"
              : "border-gray-300"
          }`}
          helperText={_renderHelperText}
          error={meta.touched && meta.error && true}
        />
        {meta.error && meta.touched ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : null}
        {meta.value && !meta.error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
        )}
      </div>

      <ErrorMessage
        component="div"
        {...rest}
        className="text-red-600 text-sm mt-1"
      />
    </>
  );
}
