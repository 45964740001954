import { useState, Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/solid";
import { usePopper } from "react-popper";

export default function PopoverComponent({ title, description }) {
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement);
  return (
    <div className="top-1">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? "" : "text-opacity-90"}
                -ml-5 lg:-ml-4 xl:-ml-8 md:-ml-5 group inline-flex items-center rounded-md text-base font-medium text-white hover:text-opacity-100 focus:outline-none dark:focus-visible:ring-2 focus-visible:ring-2 focus-visible:ring-white dark:focus-visible:ring-white focus-visible:ring-opacity-75`}
              ref={setReferenceElement}
            >
              <InformationCircleIcon
                className={`${open ? "" : "text-opacity-70"}
                h-3 w-3 dark:text-white text-black transition duration-150 ease-in-out group-hover:text-opacity-80`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
                className="absolute left-1/2 mt-3 w-screen max-w-[200px] -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-xs z-10"
              >
                <div className="overflow-hidden rounded-md shadow-lg ring-1 ring-black ring-opacity-5 border border-gray-200">
                  <div className="relative bg-gray-200 dark:bg-black py-4 px-7">
                    <p className="text-md font-medium text-gray-900 dark:text-white">
                      {title}
                    </p>
                    <p className="text-sm font-normal text-gray-900 dark:text-white mt-2">
                      {description}
                    </p>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
