import { XCircleIcon } from "@heroicons/react/solid";

export default function Alert({ message }) {
  return (
    <div className="px-6 pb-6 max-w-2xl mx-auto">
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">
              There was an error with your submission
            </h3>
            <div className="mt-2 text-sm text-red-700">
              <ul className="list-disc space-y-1 pl-5">
                {message?.map((_) => {
                  return <li key={_.path}>{_.msg}</li>;
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
