import React from "react";
import AwarenessSection from "./FormSections/AwarenessSection";

export default function AwarenessForm(props) {
  const { steps } = props;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AwarenessSection {...props} />
      {/* <Progress step={2} steps={steps} /> */}
    </>
  );
}
