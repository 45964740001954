import * as Yup from "yup";
import checkoutFormModel from "./learnMoreFormModel";

const {
  formField: {
    role_within_home_industry,
    role_within_home_industry_other,
    age,
    gender,
    gender_other,
    familiar_with_paradigm,
    hear_about_nhs,
    hear_about_nhs_other,
    rate_overall_experience,
    consider_purchasing_paradigm,
    recommend_paradigm,
    planning_purchase_six_months,
    purchase_location,
    adding_audio_renovation,
    interested_in_outdoor_audio,
    name,
    email,
    phone_number,
    contact_for_promotions,
    watch_pe_bbc,
  },
} = checkoutFormModel;

export default [
  Yup.object().shape({
    [role_within_home_industry.name]: Yup.string().required(
      role_within_home_industry.requiredErrorMsg
    ),
    [role_within_home_industry_other.name]: Yup.string()
      .ensure()
      .when("role_within_home_industry", {
        is: "Other",
        then: Yup.string().required(
          `${role_within_home_industry_other.requiredErrorMsg}`
        ),
      }),
    [age.name]: Yup.string().required(age.requiredErrorMsg),
    [gender.name]: Yup.string().required(gender.requiredErrorMsg),
    [gender_other.name]: Yup.string()
      .ensure()
      .when("gender", {
        is: "other_gender",
        then: Yup.string().required(`${gender_other.requiredErrorMsg}`),
      }),
  }),

  Yup.object().shape({
    [hear_about_nhs.name]: Yup.array()
      .min(1)
      .of(Yup.string().required("Choose atleast one option"))
      .required(),
    [hear_about_nhs_other.name]: Yup.string()
      .ensure()
      .when("hear_about_nhs", {
        is: "other",
        then: Yup.string().required(`${hear_about_nhs_other.requiredErrorMsg}`),
      }),
  }),

  Yup.object().shape({
    [rate_overall_experience.name]: Yup.string().required(
      rate_overall_experience.requiredErrorMsg
    ),
    [consider_purchasing_paradigm.name]: Yup.string().required(
      consider_purchasing_paradigm.requiredErrorMsg
    ),
    [recommend_paradigm.name]: Yup.number()
      .required(`${recommend_paradigm.requiredErrorMsg}`)
      .min(1, "Must be atleast 1")
      .max(30, "Must be less than or equal to 10."),
  }),

  Yup.object().shape({
    // [purchase_location.name]: Yup.string()
    //   .ensure()
    //   .when("planning_purchase_six_months", {
    //     is: "yes",
    //     then: Yup.string().required(`${purchase_location.requiredErrorMsg}`),
    //   }),
  }),

  Yup.object().shape({
    [name.name]: Yup.string().required(name.requiredErrorMsg),
    [phone_number.name]: Yup.string().required(phone_number.requiredErrorMsg),
    [email.name]: Yup.string()
      .ensure()
      .email("Email must be a valid email")
      .required(`${email.requiredErrorMsg}`),
  }),
];
