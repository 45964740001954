import React, { useState, useEffect, useContext, useRef } from "react";
import { Formik, Form } from "formik";
import AboutYouForm from "./Forms/AboutYouForm";
import AwarenessForm from "./Forms/AwarenessForm";
import ExperienceWithUsForm from "./Forms/ExperienceWithUsForm";
import EnterToWinForm from "./Forms/EnterToWinForm";
import AudioAffinityForm from "./Forms/AudioAffinityForm";
import validationSchema from "./FormModel/validationSchema";
import checkoutFormModel from "./FormModel/learnMoreFormModel";
import OutOfBoxSummary from "./OutOfBoxSummary/OutOfBoxSummary";
import { initialValues } from "./FormModel/formInitialValues";
import Alert from "./components/Alert";
import Layout from "./components/Layout";
import axios from "axios";
import Progress from "../../components/Progress";

const steps = [
  "About you",
  "Awareness",
  "Experience with Us",
  "Audio Affinity",
  "Enter to Win",
];
const { formId, formField } = checkoutFormModel;
const SITE_KEY = "6LdW23opAAAAAEzZ_MpTOOKAWR2b_DITQuuxOfro";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MarketingMaterial() {
  const [activeStep, setActiveStep] = useState(0);
  const [success, setSuccess] = useState(false);
  const [reachedConfirmation, setReachedConfirmation] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState([]);
  // const { brand } = useContext(BrandContext);

  const formikRef = useRef();

  function _renderStepContent(
    step,
    values,
    handleChange,
    setFieldValue,
    setFieldTouched,
    setActiveStep,
    setValues,
    handleBlur,
    errors,
    touched
  ) {
    switch (step) {
      case 0:
        return (
          <AboutYouForm
            formField={formField}
            steps={steps}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            setActiveStep={setActiveStep}
            errors={errors}
            touched={touched}
          />
        );
      case 1:
        return (
          <AwarenessForm
            formField={formField}
            steps={steps}
            values={values}
            setActiveStep={setActiveStep}
            setFieldValue={setFieldValue}
            setValues={setValues}
            setFieldTouched={setFieldTouched}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
          />
        );
      case 2:
        return (
          <ExperienceWithUsForm
            formField={formField}
            steps={steps}
            values={values}
            setActiveStep={setActiveStep}
            setFieldValue={setFieldValue}
            errors={errors}
            setFieldTouched={setFieldTouched}
            handleBlur={handleBlur}
            touched={touched}
          />
        );
      case 3:
        return (
          <AudioAffinityForm
            formField={formField}
            steps={steps}
            values={values}
            setActiveStep={setActiveStep}
            setFieldValue={setFieldValue}
            errors={errors}
            setFieldTouched={setFieldTouched}
            handleBlur={handleBlur}
            touched={touched}
          />
        );

      case 4:
        return (
          <EnterToWinForm
            formField={formField}
            steps={steps}
            values={values}
            setActiveStep={setActiveStep}
            setFieldValue={setFieldValue}
            errors={errors}
            setFieldTouched={setFieldTouched}
            handleBlur={handleBlur}
            touched={touched}
          />
        );

      default:
        return <div>Not Found</div>;
    }
  }

  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  function _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () {
        // console.log("Script loaded!");
      }
    );
  }, []);

  async function _submitForm(values, actions) {
    // setActiveStep(activeStep + 1);

    const updatedValues = {
      ...values,
      purchase_location: values?.purchase_location?.join(", "),
      hear_about_nhs: values?.hear_about_nhs?.join(", "),
    };

    // console.log(updatedValues);

    await window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, { action: "submit" })
        .then((token) => {
          axios({
            method: "post",
            url: "https://apps.paradigm.com/api/public/post_survey",
            headers: {
              "Content-Type": "application/json",
            },
            data: updatedValues,
          })
            .then((res) => {
              // console.log(res.data);
              if (res.data.status === "success") {
                setShowAlert(false);
                actions.setSubmitting(false);
                setActiveStep(activeStep + 1);
              } else if (res.data.status === "field_error") {
                setActiveStep(activeStep);
                setShowAlert(true);
                setAlertMessage([
                  {
                    path: "temp",
                    msg: "Something went wrong! Check the form again.",
                  },
                ]);
              }
            })
            .catch((err) => {
              // console.log(err);
              actions.setSubmitting(false);
              setActiveStep(activeStep);
              setShowAlert(true);
              setAlertMessage(err?.response?.data?.errors);
            });
        });
    });
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      // formikRef.current.isValid
      //   ?
      _submitForm(values, actions);
      // : alert("Handle your custom method instead here");
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
    if (showAlert) setShowAlert(false);
  }

  return (
    <React.Fragment>
      <Layout>
        {showAlert ? <Alert message={alertMessage} /> : null}
        {activeStep === steps.length ? (
          <OutOfBoxSummary />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
            innerRef={formikRef}
            // enableReinitialize
          >
            {({
              isSubmitting,
              values,
              errors,
              handleChange,
              setFieldValue,
              setFieldTouched,
              validateField,
              touched,
              isValid,
              dirty,
              setValues,
              handleBlur,
            }) => (
              <Form id={formId}>
                {_renderStepContent(
                  activeStep,
                  values,
                  handleChange,
                  setFieldValue,
                  setFieldTouched,
                  setActiveStep,
                  validateField,
                  setReachedConfirmation,
                  touched,
                  isValid,
                  dirty,
                  errors,
                  setValues,
                  handleBlur
                )}
                <div className="my-10 justify-center px-6">
                  <button
                    type="submit"
                    className="btn-next md:py-4 py-2"
                    // disabled={!(isValid && dirty)}
                    // disabled={isLastStep}
                    disabled={isSubmitting}
                  >
                    {isLastStep ? "Submit" : "Next"}
                  </button>
                  {activeStep !== 0 && (
                    <input
                      type="button"
                      onClick={_handleBack}
                      value="Back"
                      className="btn-back mt-6 cursor-pointer"
                    />
                  )}
                </div>
                <div className="mt-28">
                  <Progress
                    step={activeStep + 1}
                    steps={steps}
                    experience={"NHS Survey"}
                  />
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Layout>
    </React.Fragment>
  );
}
