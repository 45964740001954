import React from "react";
import PropTypes from "prop-types";
import { at } from "lodash";
import { useField, Field, ErrorMessage } from "formik";
import { ExclamationCircleIcon, CheckCircleIcon } from "@heroicons/react/solid";
function SelectField(props) {
  const { label, data, margin, name, placeholder, ...rest } = props;
  const [field, meta] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, "touched", "error");
  const isError = touched && error && true;
  function _renderHelperText() {
    if (isError) {
      return { error };
    }
  }

  return (
    <>
      <label
        htmlFor="country"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>

      <div className="mt-1 relative rounded-md shadow-sm">
        <Field
          as="select"
          {...field}
          placeholder="Choose title"
          className={`mt-1 p-2 block w-full shadow-sm sm:text-sm rounded-md ${
            meta.error && meta.touched
              ? "focus:border-red-500 focus:ring-red-500 border-red-500"
              : "border-gray-300"
          }`}
        >
          <option value="" hidden>
            {placeholder}
          </option>
          Select your title
          {data.map((value, index) => {
            return (
              <option key={index} value={value.value}>
                {value.label}
              </option>
            );
          })}
        </Field>
        {meta.error && meta.touched ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : null}
        {meta.value && !meta.error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
        )}
      </div>

      <ErrorMessage
        component="div"
        name={name}
        className="text-red-600 text-sm mt-1"
      />
    </>
  );
}

export default SelectField;
