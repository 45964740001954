export default {
  formId: "checkoutForm",
  formField: {
    role_within_home_industry: {
      name: "role_within_home_industry",
      requiredErrorMsg: "Choose atleast one",
    },
    role_within_home_industry_other: {
      name: "role_within_home_industry_other",
      requiredErrorMsg: "Please specify your role within the home industry",
    },
    age: {
      name: "age",
      requiredErrorMsg: "Age is required",
    },
    gender: {
      name: "gender",
      requiredErrorMsg: "Gender is required",
    },
    gender_other: {
      name: "gender_other",
      requiredErrorMsg: "Please specify your gender",
    },
    familiar_with_paradigm: {
      name: "familiar_with_paradigm",
      requiredErrorMsg: "Choose atleast one.",
    },
    hear_about_nhs: {
      name: "hear_about_nhs",
      requiredErrorMsg:
        "How did you hear about Paradigm x BBC Earth viewing lounge is required",
    },
    hear_about_nhs_other: {
      name: "hear_about_nhs_other",
      requiredErrorMsg:
        "Please specify how you heard about Paradigm x BBC Earth viewing lounge",
    },
    rate_overall_experience: {
      name: "rate_overall_experience",
      requiredErrorMsg:
        "Please rate your overall experience at our booth and with our product demo",
    },
    consider_purchasing_paradigm: {
      name: "consider_purchasing_paradigm",
      requiredErrorMsg:
        "Please tell us if you would consider purchasing Paradigm after experiencing the product demo",
    },
    recommend_paradigm: {
      name: "recommend_paradigm",
      requiredErrorMsg: "Would you recommend Paradigm is required",
    },
    contact_for_promotions: {
      name: "contact_for_promotions",
      requiredErrorMsg: "Would you like to hear from Paradigm in the future",
    },
    planning_purchase_six_months: {
      name: "planning_purchase_six_months",
      requiredErrorMsg:
        "Please tell us if you planning to purchase Paradigm products within the next 6-12 months",
    },
    purchase_location: {
      name: "purchase_location",
      requiredErrorMsg:
        "Please specify where are you planning to purchase Paradigm products within the next 6-12 months",
    },
    adding_audio_renovation: {
      name: "adding_audio_renovation",
      requiredErrorMsg:
        "Please tell us if you considering adding an audio component in your next home renovation",
    },
    interested_in_outdoor_audio: {
      name: "interested_in_outdoor_audio",
      requiredErrorMsg: "Choose one",
    },
    survey_complete: {
      name: "decor_model",
      requiredErrorMsg: "Survey Complete is required",
    },
    name: {
      name: "name",
      requiredErrorMsg: "Name is required",
    },
    email: {
      name: "email",
      requiredErrorMsg: "Email is required",
    },
    phone_number: {
      name: "phone_number",
      requiredErrorMsg: "Phone Number is required",
    },
    email_subscription: {
      name: "email_subscription",
      requiredErrorMsg: "Email is required",
    },
    watch_pe_bbc: {
      name: "watch_pe_bbc",
      requiredErrorMsg: "",
    },
  },
};
