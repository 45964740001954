import React, { useState, useEffect } from "react";
import { useField, useFormikContext, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ExclamationCircleIcon, CheckCircleIcon } from "@heroicons/react/solid";
import moment from "moment";
// import Grid from '@material-ui/core/Grid';
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker
// } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';

export default function DatePickerField(props) {
  const { errorText, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setFieldValue } = useFormikContext();
  const { touched, error } = meta;
  const { setValue } = helper;
  const isError = touched && error && true;
  const { value } = field;
  const [selectedDate, setSelectedDate] = useState(null);

  return (
    <>
      <label className="block text-sm font-medium text-gray-700">
        {props.label}
      </label>
      <div className="relative">
        <DatePicker
          {...field}
          {...props}
          // showTimeSelect
          selected={field.value || null}
          showMonthYearPicker
          onChange={(val) => {
            // const fomattedDate = moment(val).format("MM/YYYY");
            setFieldValue(field.name, val);
          }}
          dateFormat="MM/yyyy"
          className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm rounded-md
        ${
          meta.error && meta.touched
            ? "focus:border-red-500 focus:ring-red-500 border-red-500"
            : "border-gray-300"
        }`}
          autoComplete="off"
          placeholderText="Select Date"
          minDate={moment().toDate()}
        />
        {/* {meta.touched && meta.error ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : null} */}
        {/* {meta.value && !meta.error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
        )} */}
      </div>
      <ErrorMessage
        component="div"
        {...rest}
        className="text-red-600 text-sm mt-1"
      />
    </>
  );
}
