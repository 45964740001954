import React from "react";
// import PopoverComponent from "../../../../../components/PopoverComponent";
import ToggleSwitch from "../../components/ToggleSwitch";
import { FieldArray } from "formik";
import InputField from "../../components/FormFields/InputField";
const tagCollection = [
  { value: "social media", label: "Social Media" },
  { value: "email newsletter", label: "Email newsletter" },
  { value: "advertisement", label: "Advertisement" },
  { value: "word of mouth", label: "Word of mouth" },
  { value: "other", label: "Other" },
];
const EnterToWinSection = (props) => {
  const {
    formField: { name, email, phone_number, contact_for_promotions },
    steps,
    setFieldValue,
    values,
    faults,
    handleChange,
    errors,
    touched,
    initialValues,
    setValues,
    setFieldTouched,
    outOfBoxData,
  } = props;
  // console.log(values);
  return (
    <>
      <section className="px-6 lg:px-8" id="hero">
        <div className="max-w-7xl mx-auto text-gray-50">
          <h1 className="lg:text-5xl text-3xl font-bold">Enter to Win!</h1>
          <p className="py-6 text-md font-light">
            To enter the contest, please provide your contact information:
          </p>
          <div>
            <div className="">
              <InputField name={name.name} placeholder={"Enter your name"} />
            </div>
            <div className="mt-6">
              <InputField
                name={phone_number.name}
                placeholder={"Enter your phone number"}
              />
            </div>
            <div className="mt-6">
              <InputField name={email.name} placeholder={"Enter your email"} />
            </div>
          </div>
          <p className="text-lg font-semibold mt-8">
            14. Would you like to be contacted for future promotions and offers?
          </p>
          <div className="mt-6">
            <ToggleSwitch
              setFieldValue={setFieldValue}
              enabled={values.contact_for_promotions}
              name={"contact_for_promotions"}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default EnterToWinSection;
