import { CheckCircleIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Progress({ steps, step, experience }) {
  const pathname = window.location.href.includes("paradigm")
    ? "paradigm"
    : window.location.href.includes("anthem")
    ? "anthem"
    : "martinlogan";
  return (
    <section
      className={classNames(
        pathname !== "martinlogan"
          ? "bg-yellow-100 dark:bg-yellow-400 pb-8 -mt-14 px-6"
          : "bg-[#eccf96] dark:bg-green-400 pb-8 -mt-14 px-6"
      )}
    >
      <div className="lg:max-w-7xl md:max-w-2xl max-w-sm mx-auto text-center pb-10">
        {/* <p className="text-gray-900 font-bold">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit...
        </p> */}
        {/* <p className="text-gray-900 mt-3 text-xs lg:text-normal">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
          tincidunt diam sem, vitae luctus enim volutpat et. Nulla efficitur in.
        </p> */}
      </div>
      <div className={classNames("mx-auto lg:block hidden")}>
        <nav aria-label="Progress">
          <ol className="items-center flex justify-center">
            {steps?.map((x, i) => {
              return (
                <li className="mb-6 sm:mb-0" key={i}>
                  {i < step - 1 ? (
                    <>
                      <div className="flex items-center">
                        <div className="z-10 flex items-center justify-center w-8 h-8 bg-black rounded-full shrink-0">
                          <CheckCircleIcon
                            className="h-5 w-5 text-white"
                            aria-hidden="true"
                          />
                        </div>
                        {i < steps.length - 1 ? (
                          <div
                            className={classNames(
                              experience === "TV not in Database"
                                ? "sm:flex w-[90px]"
                                : "sm:flex w-[180px]",
                              "hidden bg-gray-900 h-0.5"
                            )}
                          ></div>
                        ) : null}
                      </div>
                      <div className="mt-3 sm:pr-8">
                        <h3 className="text-sm font-semibold text-[#4B5563]">
                          STEP {i + 1}
                        </h3>
                        <time className="block mb-2 text-sm font-bold leading-none text-[#1F2937] ">
                          {x}
                        </time>
                        <p className="text-sm font-normal text-gray-900">
                          Completed
                        </p>
                      </div>
                    </>
                  ) : i === step - 1 ? (
                    <>
                      <div className="flex items-center">
                        <div
                          className={classNames(
                            "z-10 flex items-center justify-center w-7 h-7 bg-white rounded-full ring-2 ring-black shrink-0"
                          )}
                        >
                          &#9679;
                        </div>
                        {i < steps.length - 1 ? (
                          <div
                            className={classNames(
                              experience === "TV not in Database"
                                ? "w-[90px]"
                                : "w-[180px]",
                              "hidden sm:flex  bg-gray-400 h-0.5"
                            )}
                          ></div>
                        ) : null}
                      </div>
                      <div className="mt-3 sm:pr-8">
                        <h3 className="text-sm font-semibold text-[#4B5563]">
                          STEP {i + 1}
                        </h3>
                        <time className="block mb-2 text-sm font-bold leading-none text-[#1F2937] ">
                          {x}
                        </time>
                        <p className="text-sm font-normal text-gray-900">
                          Ongoing
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex items-center">
                        <div className="z-10 flex items-center justify-center w-8 h-8 bg-white rounded-full ring-2 ring-gray-400 shrink-0"></div>
                        {i < steps.length - 1 ? (
                          <div
                            className={classNames(
                              experience === "TV not in Database"
                                ? "w-[90px]"
                                : "w-[180px]",
                              "hidden sm:flex  bg-gray-400 h-0.5"
                            )}
                          ></div>
                        ) : null}
                      </div>
                      <div className="mt-3 sm:pr-8">
                        <h3 className="text-sm font-semibold text-[#4B5563]">
                          STEP {i + 1}
                        </h3>
                        <time className="block mb-2 text-sm font-bold leading-none text-[#1F2937]">
                          {x}
                        </time>
                        <p className="text-sm font-normal text-gray-900">
                          Pending
                        </p>
                      </div>
                    </>
                  )}
                </li>
              );
            })}
          </ol>
        </nav>
      </div>
      <div className="visible lg:hidden max-w-xs mx-auto">
        <nav aria-label="Progress">
          <ol role="list" className="overflow-hidden">
            {steps?.map((x, i) => (
              <li
                key={i}
                className={classNames(
                  i !== steps.length - 1 ? "pb-10" : "",
                  "relative"
                )}
              >
                {i < step - 1 ? (
                  <>
                    {i < steps.length - 1 ? (
                      <div
                        className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-900"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className="group relative flex items-start">
                      <span className="flex h-9 items-center">
                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-gray-900">
                          <CheckCircleIcon
                            className="h-5 w-5 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                      <span className="ml-4 flex min-w-0 flex-col">
                        <span className="text-sm font-semibold text-[#4B5563]">
                          STEP {i + 1}
                        </span>
                        <span className="text-sm text-[#1F2937] font-bold">
                          {x}
                        </span>
                        <span className="text-sm text-gray-900">Completed</span>
                      </span>
                    </div>
                  </>
                ) : i === step - 1 ? (
                  <>
                    {i < steps.length - 1 ? (
                      <div
                        className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-400"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div
                      className="group relative flex items-start"
                      aria-current="step"
                    >
                      <span
                        className="flex h-9 items-center"
                        aria-hidden="true"
                      >
                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-900 bg-white">
                          <span className="h-2.5 w-2.5 rounded-full bg-gray-900" />
                        </span>
                      </span>
                      <span className="ml-4 flex min-w-0 flex-col">
                        <span className="text-sm font-semibold text-[#4B5563]">
                          STEP {i + 1}
                        </span>
                        <span className="text-sm text-[#1F2937] font-bold">
                          {x}
                        </span>
                        <span className="text-sm text-gray-900">Ongoing</span>
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    {i < steps.length - 1 ? (
                      <div
                        className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-400"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className="group relative flex items-start">
                      <span
                        className="flex h-9 items-center"
                        aria-hidden="true"
                      >
                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-400 bg-white">
                          <span className="h-2.5 w-2.5 rounded-full bg-transparent" />
                        </span>
                      </span>
                      <span className="ml-4 flex min-w-0 flex-col">
                        <span className="text-sm font-semibold text-[#4B5563]">
                          STEP {i + 1}
                        </span>
                        <span className="text-sm text-[#1F2937] font-bold">
                          {x}
                        </span>
                        <span className="text-sm text-gray-900">Pending</span>
                      </span>
                    </div>
                  </>
                )}
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </section>
  );
}
