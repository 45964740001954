import React from "react";
import "./social_button.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faFlickr,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const SocialButton = ({ data }) => {
  return (
    <div className="lg:my-3 my-10 max-w-6xl mx-auto">
      <ul className="social-button">
        <li>
          <a
            href="https://www.facebook.com/paradigmloudspeakers"
            target="_blank"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/paradigmnow" target="_blank">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/paradigmspeakers/" target="_blank">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/user/ParadigmSpeakersTV"
            target="_blank"
          >
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        </li>
        <li>
          <a
            href="https://www.flickr.com/photos/paradigmspeakers/albums"
            target="_blank"
          >
            <FontAwesomeIcon icon={faFlickr} />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SocialButton;
