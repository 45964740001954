import React from "react";
import AboutYou from "./FormSections/AboutYou";

export default function AboutYouForm(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AboutYou {...props} />
    </>
  );
}
