import React from "react";
import AudioAdviceSocialSurvey from "../AudioAdviceSocialSurvey/AudioAdviceSocialSurvey";

const AudioAdviceSocial = () => {
  return (
    <div>
      <AudioAdviceSocialSurvey />
    </div>
  );
};

export default AudioAdviceSocial;
