import checkoutFormModel from "./learnMoreFormModel";
const {
  formField: {
    hear_about_us,
    hear_about_us_other,
    age,
    // gender,
    // gender_other,
    recommend_martinlogan,
    planning_purchase_six_months,
    name,
    email,
    phone_number,
    contact_for_promotions,
  },
} = checkoutFormModel;

export const initialValues = {
  [hear_about_us.name]: [],
  [hear_about_us_other.name]: "",
  [age.name]: "",
  // [gender.name]: "",
  // [gender_other.name]: "",
  [recommend_martinlogan.name]: 1,
  [contact_for_promotions.name]: false,
  [planning_purchase_six_months.name]: "",
  [name.name]: "",
  [email.name]: "",
  [phone_number.name]: "",
};
