import React, { Component, useState, useEffect } from "react";
// import Slider from "react-rangeslider";

const RangeSlider = ({ name, setFieldValue, value, labelLeft, labelRight }) => {
  const [state, setState] = useState(0);
  // const [slider, setSlider] = useState();
  // const Slider = window.ReactRangeslider;

  // console.log(window);

  const handleChangeStart = () => {
    console.log("Change event started");
  };

  const handleChange = (value) => {
    // setState(value);
    setFieldValue(name, value);
    // handleChangeComplete();
  };

  const handleChangeComplete = () => {
    console.log("Change event completed");
    // setFieldValue(name, value);
  };

  // useEffect(() => {
  //   const script = document.createElement("script");

  //   script.src = "https://unpkg.com/react-rangeslider/umd/rangeslider.min.js";
  //   script.async = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <>
      <div className="slider">
        {/* <Slider
          min={1}
          max={10}
          value={value}
          orientation={"horizontal"}
          // onChangeStart={handleChangeStart}
          onChange={handleChange}
          onChangeComplete={handleChangeComplete}
        /> */}

        {/* <div className="value">{state}</div> */}
      </div>
    </>
  );
};

export default RangeSlider;
