import React from "react";

import ExperienceWithUsSection from "./FormSections/ExperienceWithUsSection";
export default function ExperienceWithUsForm(props) {
  const { steps } = props;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ExperienceWithUsSection {...props} />
      {/* <Progress step={3} steps={steps} /> */}
    </>
  );
}
