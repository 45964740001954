import React from "react";
import AudioAdviceSurvey from "../AudioAdviceSurvey";
const AudioAdviceLive = () => {
  return (
    <div>
      <AudioAdviceSurvey />
    </div>
  );
};

export default AudioAdviceLive;
