import React from "react";
import { Helmet } from "react-helmet";

const DocumentTitle = ({ title }) => {
  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
    </div>
  );
};

export default DocumentTitle;
