export const AnthemPromosData = [
  {
    seriesName: "Factory Refurbished",
    seriesHash: "FactoryRefurbished",
    seriesImgURL: "",
    seriesSalePercentage: "35",
    startDate: "2024-11-22",
    endDate: "2025-01-03",
    description:
      "Every Anthem Factory Refurbished product undergoes an extensive recertification process, including meticulous testing that matches the exact functional standards set for brand-new Anthem products. Your refurbished factory product not only performs as effectively as a new one but is also supported by a one-year warranty.",
    products: [
      {
        id: 1,
        name: "AVM 70 RFB <br/> 8K",
        imageURL:
          "https://www.anthemav.com/uploads/images/medium/avm-70-8k.png",
        promo: true,
        msrp_canada: "4899.99",
        msrp_us: "3999.99",
        startDate: "2024-11-22",
        endDate: "2025-01-03",
        discount_percentage_canada: "35",
        discount_percentage_us: "35",
        productPageUrl:
          "https://www.anthemav.com/products-current/series=factory-refurbished/model=avm-70-rfb/page=overview",
        buttonLabel: "Buy Now",
        available: true,
        sku: "1417000026X",
      },
      {
        id: 2,
        name: "MRX 540 RFB 8K",
        imageURL:
          "https://www.anthemav.com/uploads/images/medium/mrx-540-8k.png",
        promo: true,
        msrp_canada: "2299.99",
        msrp_us: "1899.99",
        startDate: "2024-11-22",
        endDate: "2025-01-03",
        discount_percentage_canada: "35",
        discount_percentage_us: "35",
        productPageUrl:
          "https://www.anthemav.com/products-current/series=factory-refurbished/model=mrx-540-rfb/page=overview",
        buttonLabel: "Buy Now",
        available: true,
        sku: "1414000077X",
      },
      {
        id: 3,
        name: "MRX 740 RFB 8K",
        imageURL:
          "https://www.anthemav.com/uploads/images/medium/mrx-740-8k.png",
        promo: true,
        msrp_canada: "3699.99",
        msrp_us: "3099.99",
        startDate: "2024-11-22",
        endDate: "2025-01-03",
        discount_percentage_canada: "35",
        discount_percentage_us: "35",
        productPageUrl:
          "https://www.anthemav.com/products-current/series=factory-refurbished/model=mrx-740-rfb/page=overview",
        buttonLabel: "Buy Now",
        available: true,
        sku: "1414000080X",
      },
      {
        id: 4,
        name: "MRX 1140 RFB 8K",
        imageURL:
          "https://www.anthemav.com/uploads/images/medium/mrx-1140-8k.png",
        promo: true,
        msrp_canada: "4999.99",
        msrp_us: "4199.99",
        startDate: "2024-11-22",
        endDate: "2025-01-03",
        discount_percentage_canada: "35",
        discount_percentage_us: "35",
        productPageUrl:
          "https://www.anthemav.com/products-current/series=factory-refurbished/model=mrx-1140-rfb/page=overview",
        buttonLabel: "Buy Now",
        available: true,
        sku: "1414000083X",
      },
      // {
      //   id: 5,
      //   name: "P5 RFB",
      //   imageURL: "https://anthemav.com/uploads/images/medium/p5.png",
      //   promo: true,
      //   msrp_canada: "11000.00",
      //   msrp_us: "8999.99",
      //   startDate: "2023-10-20",
      //   endDate: "2024-01-03",
      //   discount_percentage_canada: "35",
      //   discount_percentage_us: "35",
      //   productPageUrl: "",
      //   buttonLabel: "Sold Out",
      //   available: false,
      // },
    ],
  },
  {
    seriesName: "STR Series",
    seriesHash: "STRSeries",
    seriesImgURL: "",
    seriesSalePercentage: "15",
    endDate: "2024-01-03",
    startDate: "2023-11-02",
    description:
      "STR Series is powering the future of audio by balancing extreme power and delicate transparency, with flawless signal integrity; the benchmark of stereo performance.",
    products: [
      {
        id: 1,
        name: "STR Pre",
        imageURL:
          "https://www.anthemav.com/uploads/images/medium/str-pre-amplifier.png",
        promo: true,
        msrp_canada: "5249.99",
        discountedPrice_canada: "4462.49",
        price_diffrence_canada: "787.49",
        msrp_us: "4299.99",
        discountedPrice_us: "3654.99",
        price_diffrence_us: "644.99",
        startDate: "2023-11-02",
        endDate: "2024-01-03",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        productPageUrl:
          "https://www.anthemav.com/products-current/model=str-pre-amplifier/page=overview",
        buttonLabel: "Buy Now",
        available: true,
        sku: "",
      },

      {
        id: 2,
        name: "STR Integrated",
        imageURL:
          "https://www.anthemav.com/uploads/images/medium/str-integrated-amplifier.png",
        promo: true,
        msrp_canada: "5999.99",
        discountedPrice_canada: "5099.99",
        price_diffrence_canada: "899.99",
        msrp_us: "4999.99",
        discountedPrice_us: "4249.99",
        price_diffrence_us: "749.99",
        startDate: "2023-11-02",
        endDate: "2024-01-03",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        productPageUrl:
          "https://www.anthemav.com/products-current/model=str-integrated-amplifier/page=overview",
        buttonLabel: "Buy Now",
        available: true,
        sku: "",
      },
      {
        id: 3,
        name: "STR Power",
        imageURL: "https://cdn.pmlsound.com/anthem/str-power-amplifier-new.png",
        promo: true,
        msrp_canada: "7799.99",
        discountedPrice_canada: "6629.99",
        price_diffrence_canada: "1169.99",
        msrp_us: "6499.99",
        discountedPrice_us: "5524.99",
        price_diffrence_us: "974.99",
        startDate: "2023-11-02",
        endDate: "2024-01-03",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        productPageUrl:
          "https://www.anthemav.com/products-current/model=str-power-amplifier/page=overview",
        buttonLabel: "Buy Now",
        available: true,
        sku: "",
      },
    ],
  },
  {
    seriesName: "AVM Series",
    seriesHash: "AVMSeries",
    seriesImgURL: "",
    seriesSalePercentage: "20",
    endDate: "2025-01-03",
    startDate: "2024-10-28",
    description:
      "The Audio Video Master … a power performer. The most affordable high-end multizone controllers on the market puts the full A/V expenience in your hands!",
    products: [
      {
        id: 1,
        name: "AVM 70 8K",
        imageURL:
          "https://www.anthemav.com/uploads/images/medium/avm-70-8k.png",
        promo: true,
        msrp_canada: "4899.99",
        msrp_us: "3999.99",
        endDate: "2025-01-03",
        startDate: "2024-10-28",
        discount_percentage_canada: "20",
        discount_percentage_us: "20",
        productPageUrl:
          "https://www.anthemav.com/products-current/model=avm-70-8k/page=overview",
        buttonLabel: "Buy Now",
        available: true,
        sku: "",
      },
      {
        id: 2,
        name: "AVM 90 8K",
        imageURL:
          "https://www.anthemav.com/uploads/images/medium/avm-70-8k.png",
        promo: true,
        msrp_canada: "8999.99",
        discountedPrice_canada: "7649.99",
        price_diffrence_canada: "1349.99",
        msrp_us: "7499.99",
        discountedPrice_us: "6374.99",
        price_diffrence_us: "1124.99",
        endDate: "2025-01-03",
        startDate: "2024-10-28",
        discount_percentage_canada: "20",
        discount_percentage_us: "20",
        productPageUrl: "https://www.anthemav.com/locator/index.php",
        buttonLabel: "",
        available: true,
        sku: "",
      },
    ],
  },
  {
    seriesName: "MRX Series",
    seriesHash: "MRXSeries",
    seriesImgURL: "",
    seriesSalePercentage: "20",
    endDate: "2025-01-03",
    startDate: "2024-10-28",
    description:
      "Fourth Generation MRX Series of High-End Multichannel A/V Receivers with Anthem Room Correction and 8K Support.",
    products: [
      {
        id: 1,
        name: "MRX 540 8K",
        imageURL:
          "https://www.anthemav.com/uploads/images/medium/mrx-540-8k.png",
        promo: true,
        msrp_canada: "2299.99",
        msrp_us: "1899.99",
        endDate: "2025-01-03",
        startDate: "2024-10-28",
        discount_percentage_canada: "20",
        discount_percentage_us: "20",
        productPageUrl:
          "https://www.anthemav.com/products-current/model=mrx-540-8k/page=overview",
        buttonLabel: "Buy Now",
        available: true,
        sku: "",
      },
      {
        id: 2,
        name: "MRX 740 8K",
        imageURL:
          "https://www.anthemav.com/uploads/images/medium/mrx-740-8k.png",
        promo: true,
        msrp_canada: "3699.99",
        msrp_us: "3099.99",
        endDate: "2025-01-03",
        startDate: "2024-10-28",
        discount_percentage_canada: "20",
        discount_percentage_us: "20",
        productPageUrl:
          "https://www.anthemav.com/products-current/model=mrx-740-8k/page=overview",
        buttonLabel: "Buy Now",
        available: true,
        sku: "",
      },

      {
        id: 3,
        name: "MRX 1140 8K",
        imageURL:
          "https://www.anthemav.com/uploads/images/medium/mrx-1140-8k.png",
        promo: true,
        msrp_canada: "4999.99",
        msrp_us: "4199.99",
        endDate: "2025-01-03",
        startDate: "2024-10-28",
        discount_percentage_canada: "20",
        discount_percentage_us: "20",
        productPageUrl:
          "https://www.anthemav.com/products-current/model=mrx-1140-8k/page=overview",
        buttonLabel: "Buy Now",
        available: true,
        sku: "",
      },
      {
        id: 4,
        name: "MRX SLM",
        imageURL: "https://www.anthemav.com/uploads/images/medium/mrx-slm.png",
        promo: true,
        msrp_canada: "1499.99",
        msrp_us: "1299.99",
        endDate: "2025-01-03",
        startDate: "2024-10-28",
        discount_percentage_canada: "13.333334",
        discount_percentage_us: "23.07710059",
        productPageUrl:
          "https://www.anthemav.com/products-current/model=mrx-slm/page=overview",
        buttonLabel: "Buy Now",
        available: true,
        sku: "",
      },
    ],
  },

  {
    seriesName: "MCA Series",
    seriesHash: "MCASeries",
    seriesImgURL: "",
    seriesSalePercentage: "20",
    startDate: "2024-11-22",
    endDate: "2025-01-03",
    description:
      "Superb Value in Multichannel Amplifiers, delivering 225 continuous watts per channel into 8 ohms.",
    products: [
      {
        id: 1,
        name: "MCA 225 v2",
        imageURL:
          "https://www.anthemav.com/uploads/images/medium/mca-225-gen2.png",
        promo: true,
        msrp_canada: "2699.99",
        discountedPrice_canada: "2294.99",
        price_diffrence_canada: "404.99",
        msrp_us: "2299.99",
        discountedPrice_us: "1954.99",
        price_diffrence_us: "344.99",
        startDate: "2024-11-22",
        endDate: "2025-01-03",
        discount_percentage_canada: "20",
        discount_percentage_us: "20",
        productPageUrl:
          "https://www.anthemav.com/products-current/model=mca-225-gen2/page=overview",
        buttonLabel: "Buy Now",
        available: true,
        sku: "",
      },
      {
        id: 2,
        name: "MCA 325 v2",
        imageURL:
          "	https://www.anthemav.com/uploads/images/medium/mca-325-gen2.png",
        promo: true,
        msrp_canada: "3199.99",
        discountedPrice_canada: "2719.99",
        price_diffrence_canada: "479.99",
        msrp_us: "2699.99",
        discountedPrice_us: "2294.99",
        price_diffrence_us: "404.99",
        startDate: "2024-11-22",
        endDate: "2025-01-03",
        discount_percentage_canada: "20",
        discount_percentage_us: "20",
        productPageUrl:
          "https://www.anthemav.com/products-current/model=mca-325-gen2/page=overview",
        buttonLabel: "Buy Now",
        available: true,
        sku: "",
      },
      {
        id: 3,
        name: "MCA 525 v2",
        imageURL:
          "https://www.anthemav.com/uploads/images/medium/mca-525-gen2.png",
        promo: true,
        msrp_canada: "4199.99",
        discountedPrice_canada: "3569.99",
        price_diffrence_canada: "629.99",
        msrp_us: "3799.99",
        discountedPrice_us: "3229.99",
        price_diffrence_us: "569.99",
        startDate: "2024-11-22",
        endDate: "2025-01-03",
        discount_percentage_canada: "20",
        discount_percentage_us: "20",
        productPageUrl:
          "https://www.anthemav.com/products-current/model=mca-525-gen2/page=overview",
        buttonLabel: "Buy Now",
        available: true,
        sku: "",
      },
    ],
  },

  // {
  //   seriesName: "MDX Series",
  //   seriesHash: "MDXSeries",
  //   seriesImgURL: "",
  //   seriesSalePercentage: "20",
  //   endDate: "2025-01-03",
  //   startDate: "2024-10-28",
  //   description:
  //     "New Anthem MDX Series are the first distribution systems to feature fully powered independent-zone amplification with subwoofer connections, as well as ARC® Genesis room correction for each zone.",
  //   products: [
  //     {
  //       id: 1,
  //       name: "MDX-12",
  //       imageURL: "https://www.anthemav.com/uploads/images/medium/mdx-12.png",
  //       promo: true,
  //       msrp_canada: "1899.99",
  //       msrp_us: "1599.99",
  //       endDate: "2025-01-03",
  //       startDate: "2024-10-28",
  //       discount_percentage_canada: "25",
  //       discount_percentage_us: "25",
  //       productPageUrl:
  //         "https://www.anthemav.com/products-current/model=avm-70-8k/page=overview",
  //       buttonLabel: "Buy Now",
  //       available: true,
  //     },
  //   ],
  // },
  // {
  //   seriesName: "MRX-SLM Series",
  //   seriesHash: "MRXSLMSeries",
  //   seriesImgURL: "",
  //   seriesSalePercentage: "20",
  //   endDate: "2025-01-03",
  //   startDate: "2024-10-10",
  //   description:
  //     "The Audio Video Master … a power performer. The most affordable high-end multizone controllers on the market puts the full A/V expenience in your hands!",
  //   products: [
  //     {
  //       id: 1,
  //       name: "AVM 70 8K",
  //       imageURL:
  //         "https://www.anthemav.com/uploads/images/thumbnails/mrx-slm.jpg",
  //       promo: true,
  //       msrp_canada: "4899.99",

  //       msrp_us: "3999.99",

  //       endDate: "2025-01-03",
  //       startDate: "2024-10-10",
  //       discount_percentage_canada: "20",
  //       discount_percentage_us: "20",
  //       productPageUrl:
  //         "https://www.anthemav.com/products-current/model=avm-70-8k/page=overview",
  //       buttonLabel: "Buy Now",
  //       available: true,
  //     },
  //   ],
  // },
];
