import { useField } from "formik";
import { MultiSelect } from "react-multi-select-component";

export const MultiSelectField = ({ name, ...otherProps }) => {
  const [field, meta, helpers] = useField({ name });

  return (
    <MultiSelect
      value={field.value}
      onChange={(items) => helpers.setValue(items)}
      {...otherProps}
    />
  );
};
