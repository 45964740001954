import checkoutFormModel from "./learnMoreFormModel";
const {
  formField: {
    role_within_home_industry,
    role_within_home_industry_other,
    age,
    gender,
    gender_other,
    familiar_with_paradigm,
    hear_about_nhs,
    hear_about_nhs_other,
    rate_overall_experience,
    consider_purchasing_paradigm,
    recommend_paradigm,
    contact_for_promotions,
    planning_purchase_six_months,
    purchase_location,
    adding_audio_renovation,
    interested_in_outdoor_audio,
    name,
    email,
    phone_number,
    watch_pe_bbc,
  },
} = checkoutFormModel;

export const initialValues = {
  [role_within_home_industry.name]: "",
  [role_within_home_industry_other.name]: "",
  [age.name]: "",
  [gender.name]: "",
  [gender_other.name]: "",
  [familiar_with_paradigm.name]: false,
  [hear_about_nhs.name]: [],
  [hear_about_nhs_other.name]: "",
  [rate_overall_experience.name]: "",
  [consider_purchasing_paradigm.name]: "",
  [recommend_paradigm.name]: 1,
  [contact_for_promotions.name]: false,
  [planning_purchase_six_months.name]: false,
  [purchase_location.name]: [],
  [adding_audio_renovation.name]: false,
  [interested_in_outdoor_audio.name]: false,
  [name.name]: "",
  [email.name]: "",
  [phone_number.name]: "",
  [watch_pe_bbc.name]: false,
};
