import React from "react";
import "./HorizontalScrollTabs.css";
import { useNavigate } from "react-router-dom";
import { NavHashLink, HashLink } from "react-router-hash-link";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const HorizontalScrollTabs = ({ data }) => {
  const navigate = useNavigate();

  const handlechange = (target) => {
    window.location.href = `/promos#${target}`;
  };
  return (
    <div className="pt-10">
      <div className="sm:hidden px-6">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-yellow-500 focus:ring-yellow-500"
          onChange={(event) => handlechange(event.target.value)}
        >
          {data.map((tab) => (
            <option key={tab.seriesName} value={tab.seriesHash}>
              {tab.seriesName}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:inline-block md:px-6 lg:px-0 px-0">
        <nav
          className="isolate flex items-center divide-x divide-gray-200 rounded-lg shadow"
          aria-label="Tabs"
        >
          {data.map((tab, tabIdx) => (
            <NavHashLink
              smooth
              to={`/promos#${tab.seriesHash}`}
              className=" text-gray-900 p-3"
            >
              <img
                src={tab.seriesImgURL}
                alt={tab.seriesName}
                className={classNames(data.length < 5 ? "w-44" : "w-full")}
              />
              {tab.seriesHash === "FactoryRefurbished" ? (
                <span className="absolute -mt-2 px-12">
                  Upto{" "}
                  <span className="text-red-600 font-bold">
                    {tab.seriesSalePercentage}% OFF
                  </span>
                </span>
              ) : null}
            </NavHashLink>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default HorizontalScrollTabs;
