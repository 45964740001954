import checkoutFormModel from "./learnMoreFormModel";
const {
  formField: {
    attraction_to_audio_advice_live,
    attraction_to_audio_advice_live_other,
    attraction_to_audio_advice_live_products,
    age,
    // gender,
    // gender_other,
    familiar_with_martinlogan,
    rate_overall_experience,
    consider_purchasing_martinlogan,
    recommend_martinlogan,
    planning_purchase_six_months,
    name,
    email,
    phone_number,
    contact_for_promotions,
  },
} = checkoutFormModel;

export const initialValues = {
  [attraction_to_audio_advice_live_other.name]: "",
  [attraction_to_audio_advice_live_products.name]: "",
  [age.name]: "",
  // [gender.name]: "",
  // [gender_other.name]: "",
  [familiar_with_martinlogan.name]: false,
  [attraction_to_audio_advice_live.name]: [],
  [rate_overall_experience.name]: "",
  [consider_purchasing_martinlogan.name]: "",
  [recommend_martinlogan.name]: 1,
  [contact_for_promotions.name]: false,
  [planning_purchase_six_months.name]: "",
  [name.name]: "",
  [email.name]: "",
  [phone_number.name]: "",
};
