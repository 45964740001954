import React from "react";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Layout = ({ children }) => {
  const navigate = useNavigate();

  const handleClick = () => {};

  return (
    <div className="flex flex-col justify-between bg-gradient-to-b from-[#204870] to-[#192735]">
      <div className="flex justify-center py-6 max-w-2xl items-center container mx-auto">
        {/* <a href="/nhs_survey"> */}
        <img
          src={"https://cdn.pmlsound.com/paradigm/paradigm_logo.png"}
          alt="logo"
          className={classNames("w-8/12", "cursor-pointer")}
          onClick={handleClick}
        />
        {/* </a> */}
      </div>
      <div className="container mx-auto xl:max-w-7xl">
        <div className="py-10">
          <div>{children}</div>
        </div>
      </div>
      <div className="flex flex-col justify-center max-w-2xl items-center container mx-auto">
        <img
          src={"https://cdn.pmlsound.com/paradigm/paradigm_logo.png"}
          alt="logo"
          className={classNames("md:w-48 md:h-9 w-28 h-6")}
        />
        <p className="text-gray-50 pb-10">
          © {new Date().getFullYear()} Paradigm Electronics. All rights
          reserved.
        </p>
      </div>
    </div>
  );
};

export default Layout;
