import * as Yup from "yup";
import checkoutFormModel from "./learnMoreFormModel";

const {
  formField: {
    hear_about_us,
    hear_about_us_other,
    age,
    // gender,
    // gender_other,
    recommend_martinlogan,
    planning_purchase_six_months,
    name,
    email,
    phone_number,
  },
} = checkoutFormModel;

export default [
  Yup.object().shape({
    [hear_about_us.name]: Yup.array()
      .min(1)
      .of(Yup.string().required("Choose atleast one option"))
      .required(),
    [hear_about_us_other.name]: Yup.string()
      .ensure()
      .when("hear_about_us", (a, b) => {
        if (a?.includes("other")) {
          return b.required(hear_about_us_other.requiredErrorMsg);
        } else {
          return b;
        }
      }),
    [age.name]: Yup.string().required(age.requiredErrorMsg),
    // [gender.name]: Yup.string().required(gender.requiredErrorMsg),
    // [gender_other.name]: Yup.string()
    //   .ensure()
    //   .when("gender", {
    //     is: "other_gender",
    //     then: Yup.string().required(`${gender_other.requiredErrorMsg}`),
    //   }),
  }),

  Yup.object().shape({
    [recommend_martinlogan.name]: Yup.number()
      .required(`${recommend_martinlogan.requiredErrorMsg}`)
      .min(1, "Must be atleast 1")
      .max(30, "Must be less than or equal to 10."),
    [planning_purchase_six_months.name]: Yup.string().required(
      planning_purchase_six_months.requiredErrorMsg
    ),
  }),

  Yup.object().shape({
    [name.name]: Yup.string().required(name.requiredErrorMsg),
    [phone_number.name]: Yup.string().required(phone_number.requiredErrorMsg),
    [email.name]: Yup.string()
      .ensure()
      .email("Email must be a valid email")
      .required(`${email.requiredErrorMsg}`),
  }),
];
