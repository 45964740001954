import React from "react";
import { at } from "lodash";
import { useField, Field, ErrorMessage } from "formik";

const Radio = (props) => {
  const { label, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;

  function _renderHelperText() {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return { error };
    }
  }

  function _onChange(e) {
    setValue(e.target.checked);
  }

  return (
    <>
      <div className="flex items-center">
        <Field
          // id="proprietorship"
          {...field}
          {...rest}
          type="radio"
          className="focus:ring-gray-800 h-4 w-4 text-gray-800 border-gray-300"
        />
        <label
          htmlFor="proprietorship"
          className="ml-3 block text-sm font-medium text-gray-700"
        >
          {rest.label}
        </label>
      </div>
      {/* <ErrorMessage
        component="div"
        {...rest}
        className="text-red-600 text-sm mt-1"
      /> */}
    </>
  );
};

export default Radio;
