import React, { useContext } from "react";
import RangeSlider from "../../components/RangeSlider";
import RangeSliderRC from "../../components/RangeSliderRC";
// import PopoverComponent from "../../../../../components/PopoverComponent";

const experienceOptions = [
  { title: "Excellent", value: "excellent" },
  { title: "Very good", value: "very good" },
  { title: "Good", value: "good" },
  { title: "Fair", value: "fair" },
  { title: "Poor", value: "poor" },
];

const purchasingOptions = [
  { title: "Yes, definitely", value: "yes, definitely" },
  { title: "Yes, maybe", value: "yes, maybe" },
  { title: "Not sure", value: "not sure" },
  { title: "No, not likely", value: "no, not likely" },
];

const purchasingSixMonthsOptions = [
  { title: "Yes", value: "yes" },
  { title: "No", value: "no" },
  { title: "Maybe", value: "maybe" },
];

const ExperienceWithUsSection = (props) => {
  const {
    formField: {
      rate_overall_experience,
      consider_purchasing_martinlogan,
      recommend_martinlogan,
      planning_purchase_six_months,
    },
    steps,
    setFieldValue,
    values,
    faults,
    handleChange,
    errors,
    touched,
    outOfBoxData,
  } = props;

  // console.log(values);

  return (
    <section className="px-6 lg:px-8" id="hero">
      <div className="max-w-2xl mx-auto text-gray-50">
        <h1 className="lg:text-5xl text-3xl font-bold">Experience with Us</h1>
        <p className="py-6 text-md font-light">
          How did we do? Let us know about our experience at our booth and
          product demo.
        </p>

        {/* <div className="mt-3">
          <p className="text-lg font-semibold">
            5. How would you rate your overall experience at our rooms and with
            our product demonstrations?
          </p>
          <div className="mt-6 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-4">
            {experienceOptions.map((_) => {
              return (
                <div key={_.title}>
                  <input
                    className="hidden"
                    id={_.value}
                    type="radio"
                    name="experience"
                    onChange={(e) => {
                      // console.log(e.target.id);
                      setFieldValue("rate_overall_experience", e.target.id);
                    }}
                    checked={values.rate_overall_experience === _.value}
                  />
                  <label
                    className="btn-province-small cursor-pointer text-center border-green-500 hover:bg-green-500"
                    htmlFor={_.value}
                  >
                    {_.title}
                  </label>
                </div>
              );
            })}
          </div>
          <div className="mt-3">
            {errors.rate_overall_experience &&
            !values.rate_overall_experience ? (
              <div className="text-red-600 md:text-2xl text-lg">
                Choose one option
              </div>
            ) : null}
          </div>
        </div>

        <div className="mt-3">
          <p className="text-lg font-semibold pt-6">
            6. Would you consider purchasing MartinLogan products after
            experiencing one of the demonstrations?
          </p>
          <div className="mt-6 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-4">
            {purchasingOptions.map((_) => {
              return (
                <div key={_.title}>
                  <input
                    className="hidden"
                    id={_.value}
                    type="radio"
                    name="puchasing"
                    onChange={(e) => {
                      // console.log(e.target.id);
                      setFieldValue(
                        "consider_purchasing_martinlogan",
                        e.target.id
                      );
                    }}
                    checked={values.consider_purchasing_martinlogan === _.value}
                  />
                  <label
                    className="btn-province-small cursor-pointer text-center border-green-500 hover:bg-green-500"
                    htmlFor={_.value}
                  >
                    {_.title}
                  </label>
                </div>
              );
            })}
          </div>
          <div className="mt-3">
            {errors.consider_purchasing_martinlogan &&
            !values.consider_purchasing_martinlogan ? (
              <div className="text-red-600 md:text-2xl text-lg">
                Choose one option
              </div>
            ) : null}
          </div>
        </div> */}

        <div className="mt-10">
          <p className="text-lg font-semibold mb-6">
            7. On a scale of 1 to 10, how likely are you to recommend
            MartinLogan to a friend or colleague? (1 being not likely and 10
            being extremely likely)
          </p>
          <div className="py-10">
            {/* <RangeSlider
              name={"recommend_paradigm"}
              setFieldValue={setFieldValue}
              value={values.recommend_paradigm}
              labelLeft={"Not Likely"}
              labelRight={"Extremely Likely"}
            /> */}
            <RangeSliderRC
              name={"recommend_martinlogan"}
              setFieldValue={setFieldValue}
              formValue={values.recommend_martinlogan}
            />
            <div className="flex justify-between mt-8">
              <span className="text-gray-50">Not Likely</span>
              <span className="text-gray-50">Extremely Likely</span>
            </div>

            {errors.recommend_martinlogan &&
            values.recommend_martinlogan < 1 ? (
              <div className="text-red-600 md:text-2xl text-lg">
                This is a required field
              </div>
            ) : null}
          </div>
        </div>

        <div className="mt-3">
          <p className="text-lg font-semibold">
            8. Are you planning to purchase MartinLogan products within the next
            6-12 months?
          </p>
          <div className="mt-6 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-4">
            {purchasingSixMonthsOptions.map((_) => {
              return (
                <div key={_.title}>
                  <input
                    className="hidden"
                    id={_.value}
                    type="radio"
                    name="puchasing_six"
                    onChange={(e) => {
                      // console.log(e.target.id);
                      setFieldValue(
                        "planning_purchase_six_months",
                        e.target.id
                      );
                    }}
                    checked={values.planning_purchase_six_months === _.value}
                  />
                  <label
                    className="btn-province-small cursor-pointer text-center border-[#d59028] hover:bg-[#d59028]"
                    htmlFor={_.value}
                  >
                    {_.title}
                  </label>
                </div>
              );
            })}
          </div>
          <div className="mt-3">
            {errors.planning_purchase_six_months &&
            !values.planning_purchase_six_months ? (
              <div className="text-red-600 md:text-2xl text-lg">
                Choose one option
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExperienceWithUsSection;
