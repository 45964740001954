import { useEffect, useState, createContext } from "react";
import "./App.css";
import { Routes, Route, Link } from "react-router-dom";

import ParadigmPromosLandingPage from "./promosApp/paradigm_promos/pages/ParadigmPromosLandingPage";
import AnthemPromosLandingPage from "./promosApp/anthem_promos/pages/AnthemPromosLandingPage";
import axios from "axios";
import Form from "./dealerAgreementApp/Form";
import Dashboard from "./dashboard/dashboard";
import NotFound from "./notFound/NotFound";
import MartinLoganPromosLandingPage from "./promosApp/martinlogan_promos/pages/MartinLoganPromosLandingPage";
import NhsSurvey from "./nhsSurvey/NhsSurvey";
import NhsSocial from "./nhsSurvey/pages/NhsSocial";
import NhsLive from "./nhsSurvey/pages/NhsLive";
import AudioAdviceSocial from "./audio-advice-survey/pages/AudioAdviceSocial";
import AudioAdviceLive from "./audio-advice-survey/pages/AudioAdviceLive";

export const appContext = createContext();

function App() {
  const pathname = window.location.href.includes("paradigm")
    ? "paradigm"
    : window.location.href.includes("anthem")
    ? "anthem"
    : "martinlogan";

  // const pathname = window.location.href.includes("promos");
  const [country, setCountry] = useState("CA");
  const [mode, setMode] = useState(
    localStorage.getItem("colorMode") || "light"
  );

  useEffect(() => {
    if (mode != "light") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("colorMode", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("colorMode", "light");
    }
    return () => {
      console.log("Mode Changed!");
    };
  }, [mode]);

  const getData = async () => {
    const res = await axios
      .get("https://api64.ipify.org?format=json")
      .then((res2) => {
        // if (res2.data.ip.length)
        // console.log(res2);
        return axios.get(
          `https://decor.paradigm.com/ds/api/location/${res2.data.ip}`
        );
      })
      .then((res3) => {
        // console.log(res3);
        setCountry(res3.data.location.iso_code);
      });
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  return (
    <appContext.Provider value={{ country, mode, setMode }}>
      <Routes>
        <Route path="/agreement" element={<Form />} />
        <Route path="*" exact={true} element={<NotFound />} />
        <Route
          path="/promos"
          element={
            country === "CA" || country === "US" ? (
              pathname === "paradigm" ? (
                <ParadigmPromosLandingPage />
              ) : pathname === "anthem" ? (
                <AnthemPromosLandingPage />
              ) : (
                <MartinLoganPromosLandingPage />
              )
            ) : (
              <NotFound />
            )
          }
        />

        {/* <Route
          path="/aa_live"
          element={
            country === "CA" || country === "US" ? (
              pathname === "martinlogan" ? (
                <AudioAdviceLive />
              ) : (
                <NotFound />
              )
            ) : (
              <NotFound />
            )
          }
        />
        <Route
          path="/aa_social"
          element={
            country === "CA" || country === "US" ? (
              pathname === "martinlogan" ? (
                <AudioAdviceSocial />
              ) : (
                <NotFound />
              )
            ) : (
              <NotFound />
            )
          }
        /> */}
        {/* <Route
          path="/aa_live"
          element={
            country === "CA" && pathname === "martinlogan" ? (
              <AudioAdviceLive />
            ) : (
              <NotFound />
            )
          }
        /> */}

        {/* <Route
          path="/nhs_survey"
          element={
            country === "CA" && pathname === "paradigm" ? (
              <NhsSurvey />
            ) : (
              <NotFound />
            )
          }
        />
        <Route
          path="/nhs_social"
          element={pathname === "paradigm" ? <NhsSocial /> : <NotFound />}
        />
        <Route
          path="/nhs_live"
          element={
            country === "CA" && pathname === "paradigm" ? (
              <NhsLive />
            ) : (
              <NotFound />
            )
          }
        /> */}
      </Routes>
    </appContext.Provider>
  );
}

export default App;
